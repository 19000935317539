import styled, { css } from 'styled-components';
import { mediaMd, mediaSm } from 'theme/theme-helper-selectors';

export const Wrapper = styled.div`
    border-bottom: 1px solid #666666;
    overflow: hidden;

    &:last-child {
        border: none;
    }
`;

export const Question = styled.div`
    width: 100%;
    text-align: left;
    display: flex;
    padding: 25px 0;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    cursor: pointer;

    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const AnswerContainer = styled.div<{ active: boolean }>`
    height: ${({ active }) => (active ? 'auto' : 0)};
`;

export const listMixin = css`
    ul {
        li {
            margin: 0 0 10px 0;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: flex-start;

            &:before {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: ${({ theme }) => theme.palette.teal};
                content: ' ';
                flex-shrink: 0;
                margin-top: 5px;
            }
        }
    }
`;

export const AnswerContent = styled.div`
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 21px;
    padding: 0 0 20px 0;

    p,
    ul {
        margin: 0 0 15px 0;
    }

    a {
        color: ${({ theme }) => theme.palette.teal};
        font-weight: normal;
    }

    ${listMixin}

    .highlight {
        color: ${({ theme }) => theme.palette.white};
        font-weight: normal;
    }
`;

export const IconContainer = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    border: ${({ theme }) => `1px solid ${theme.palette.teal}`};
`;

export const QuestionText = styled.p`
    width: 80%;
`;

export const Disclaimer = styled.p`
    font-size: 24px;
    line-height: 27px;
    margin: 40px 0 0 0;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 21px;
    }

    a {
        color: #3de8ca;
        font-weight: 500;
    }
`;
