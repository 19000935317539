import styled from 'styled-components';
import { colorGray, mediaTablet } from '../../../theme/theme-helper-selectors';

export const FooterText = styled.p`
    margin-right: 10px;
    max-width: 200px;
    font-size: 10px;
    color: ${colorGray};
    text-align: center;

    @media (${mediaTablet}) {
        text-align: left;
    }
`;
