import React, { PropsWithChildren } from 'react';
import { GlobalStyle } from 'styles/global-styles';
import { ConfigProvider, LangStateProvider, ThemeStateProvider, ApiProvider } from 'contexts';
import { GlobalStyles as UiKitGlobalStyles } from 'kl-b2c-ui-kit';
import { NormalizeCss } from 'styles/normalize-css';

const CoreProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => (
    <ApiProvider>
        <ThemeStateProvider>
            <UiKitGlobalStyles />
            <NormalizeCss />
            <GlobalStyle />
            <LangStateProvider>
                <ConfigProvider>{children}</ConfigProvider>
            </LangStateProvider>
        </ThemeStateProvider>
    </ApiProvider>
);

export default CoreProvider;
