import styled, { css } from 'styled-components';
import { colorGray, colorPrimaryLight, mediaXs } from '../../../theme/theme-helper-selectors';
import { Link } from 'react-router-dom';

const linkStyles = css`
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: ${colorPrimaryLight};
    border-bottom: 1px solid ${colorPrimaryLight};
    transition: all 0.5s ease;
    text-decoration: none;
`;

export const FooterMenuList = styled.ul`
    display: flex;
    padding: 0;
    margin: 0;
`;

export const FooterMenuItem = styled.li`
    position: relative;
    display: inline-block;
    margin-right: 8px;
    font-weight: 300;

    @media (${mediaXs}) {
        margin-right: 15px;
    }

    &:before {
        content: '•';
        margin-right: 7px;
        color: ${colorGray};

        @media (${mediaXs}) {
            display: none;
        }
    }
`;

export const FooterMenuLink = styled(Link)`
    ${linkStyles}
`;

export const FooterMenuExternalLink = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    ${linkStyles}
`;
