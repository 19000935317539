import React, { createContext, useContext, FC, PropsWithChildren } from 'react';
import { getApiService, getStorageService } from 'services';

const storageService = getStorageService();
const api = getApiService(storageService);

const ApiContext = createContext<typeof api>(api);

const ApiProvider: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);

export const useApi = () => useContext(ApiContext);

export default ApiProvider;
