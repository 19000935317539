import React, { useEffect } from 'react';
import { useSettings } from 'contexts';
import { CoreProvider, AppRouter } from 'containers';
import { useUtmLSParams } from 'kl-b2c-ui-kit';
import './config/i18n';

import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
dayjs.locale('en');

const App = () => {
    const { settings } = useSettings();

    useEffect(() => {
        useUtmLSParams();
    }, []);

    return !settings?.apiAddress ? null : (
        <CoreProvider>
            <AppRouter />
        </CoreProvider>
    );
};

export default App;
