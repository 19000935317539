import styled from 'styled-components';
import { mediaLg, mediaMd } from 'theme/theme-helper-selectors';

export const Container = styled.div`
    margin: 0 auto 160px auto;
    max-width: 1200px;
    text-align: center;

    @media (${mediaLg}) {
        margin: 0 auto 120px auto;
    }

    @media (${mediaMd}) {
        margin: 0 auto 100px auto;
    }
`;

export const Title = styled.h4`
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
    margin: 0 0 48px 0;
`;

export const CarouselContainer = styled.div`
    margin: 0 0 50px 0;

    @media (${mediaMd}) {
        margin: 0 0 30px 0;
    }
`;

export const SponsorLogo = styled.img`
    max-height: 105px;
    max-width: 300px;
    padding: 0 24px;
    align-self: center;
`;

export const SliderContainer = styled.div`
    margin: 0 0 65px 0;
    mask-image: ${({ theme }) =>
        `linear-gradient(to right, transparent 0%, ${theme.palette.background} 20%, ${theme.palette.background} 80%, transparent 100%)`};
`;
