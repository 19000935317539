import React, { FC } from 'react';
import { NativeNavLink, RouterNavLink } from './styled';
import { HeaderMenu } from 'types';
import { useTranslation } from 'react-i18next';
import { useMobileMenuContext } from 'contexts';

type HeaderLinkProps = HeaderMenu & {
    className?: string;
    level?: number;
    actionClick?: (actionType: string) => void;
};

const HeaderLink: FC<HeaderLinkProps> = (props) => {
    const { title, dataAtSelector, tooltip, ...rest } = props;
    const { t } = useTranslation('common/header-nav');
    const { toggleMobileMenu } = useMobileMenuContext();

    const content = <span title={tooltip}>{t(title as unknown as TemplateStringsArray)}</span>;

    if (props.isAnchor) {
        return (
            <NativeNavLink
                onClick={() => toggleMobileMenu(false)}
                href={props.path}
                data-at-selector={dataAtSelector}
                {...rest}
            >
                {content}
            </NativeNavLink>
        );
    }

    if (props.isExternal) {
        return (
            <NativeNavLink
                rel="noopener noreferrer"
                href={props.path}
                target={props.target || '_blank'}
                data-at-selector={dataAtSelector}
                {...rest}
            >
                {content}
            </NativeNavLink>
        );
    }

    return (
        <RouterNavLink
            className={({ isActive }) => (isActive ? 'active' : '')}
            to={props.path}
            data-at-selector={dataAtSelector}
            {...rest}
        >
            {content}
        </RouterNavLink>
    );
};

export default HeaderLink;
