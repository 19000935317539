import styled from 'styled-components';
import { colorWhite } from '../../../../theme/theme-helper-selectors';

export const HamburgerLine = styled.span`
    width: 20px;
    height: 3px;
    border-radius: 4px;
    background-color: ${colorWhite};
    display: block;
    margin: 4px auto;
    transition: transform 0.3s ease-in-out;

    &:nth-child(1) {
        margin-top: 0;
    }

    &:nth-child(3) {
        margin-bottom: 0;
    }
`;
