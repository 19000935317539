import React, { FC } from 'react';
import { HeaderProps } from '../Header';
import { MobileWrap, Nav, NavBarCollapse, NavList, NavListItem } from './styled';
import { useMobileMenuContext } from 'contexts/mobile-menu.context';
import HeaderLink from './header-link/HeaderLink';

export const HeaderNav: FC<HeaderProps> = ({ links }) => {
    const { isMobileMenuOpened } = useMobileMenuContext();

    return (
        <Nav data-at-selector="pageHeader" isHidden={!isMobileMenuOpened}>
            <NavBarCollapse collapse={!isMobileMenuOpened}>
                <MobileWrap>
                    <NavList>
                        {links.map((item) => (
                            <NavListItem key={item.title}>
                                <HeaderLink {...item} />
                            </NavListItem>
                        ))}
                    </NavList>
                </MobileWrap>
            </NavBarCollapse>
        </Nav>
    );
};
