/* eslint-disable import/no-named-as-default-member */
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languages } from 'consts/languages';

i18next
    .use(
        resourcesToBackend(
            (language: string, namespace: string) => import(`../../public/locales/${language}/common/${namespace}.json`)
        )
    )
    .use(
        resourcesToBackend(
            (language: string, namespace: string) => import(`../../public/locales/${language}/pages/${namespace}.json`)
        )
    )
    .use(
        resourcesToBackend(
            (language: string, namespace: string) => import(`../../public/locales/${language}/${namespace}.json`)
        )
    )
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        defaultNS: 'translation',
        load: 'languageOnly',
        fallbackLng: Object.keys(languages)[0],
        supportedLngs: Object.keys(languages),
        debug: process.env.NODE_ENV === 'development',
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
