import React, { FC, useEffect, useState } from 'react';
import { Container, Title, SponsorLogo, SliderContainer } from './styled';
import { useTranslation } from 'react-i18next';
// import DOMPurify from 'dompurify';
// import { Icon } from 'kl-b2c-ui-kit';
// import { NavLink } from 'react-router-dom';
// import { ButtonContent, ButtonLabel, ButtonIconContainer, IntroButton } from '../intro/styled';
import { getStorageService, getApiService, getSponsorsApiService } from 'services/api.factory';
import { Sponsor } from 'types';
import Marquee from 'react-fast-marquee';

const Sponsors: FC = () => {
    const { t } = useTranslation('pages/home');
    const storageService = getStorageService();
    const apiService = getApiService(storageService);
    const sponsorsApiService = getSponsorsApiService(apiService);
    const [sponsors, setSponsors] = useState<Sponsor[] | null>(null);

    useEffect(() => {
        sponsorsApiService.getAll({ page: 0, size: 300 }).then((response) => {
            setSponsors(response.data.items);
        });
    }, []);

    if (!sponsors || sponsors.length === 0) {
        return null;
    }

    return (
        <Container>
            <Title>{t('sponsors-title')}</Title>

            <SliderContainer>
                <Marquee direction="left" speed={30} gradient={false}>
                    {sponsors.map((sponsor) => (
                        <SponsorLogo
                            key={window.crypto.randomUUID()}
                            src={sponsor.image.fileLink}
                            alt={sponsor.image.fileName}
                        />
                    ))}
                    {sponsors.map((sponsor) => (
                        <SponsorLogo
                            key={window.crypto.randomUUID()}
                            src={sponsor.image.fileLink}
                            alt={sponsor.image.fileName}
                        />
                    ))}
                </Marquee>
            </SliderContainer>

            {/*@ts-ignore*/}
            {/* <IntroButton type={'transparent'} size={'large'}>
                <NavLink to={'/sponsors'}>
                    <ButtonContent>
                        <ButtonLabel dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('sponsor')) }} />
                        <ButtonIconContainer>
                            <Icon name={'ArrowDiagonale'} size={21} color={'black'} />
                        </ButtonIconContainer>
                    </ButtonContent>
                </NavLink>
            </IntroButton> */}
        </Container>
    );
};

export default Sponsors;
