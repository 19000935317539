import styled from 'styled-components';
import { mediaTablet } from '../../../theme/theme-helper-selectors';

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (${mediaTablet}) {
        flex-direction: row;
    }
`;
