import styled from 'styled-components';
import { colorGray, colorPrimary, mediaMobile, mediaXs } from '../../../theme/theme-helper-selectors';

export const FooterSocialsList = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: row;

    @media (${mediaXs}) {
        margin: 0 0 10px 0;
    }
`;

export const FooterSocial = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer',
})`
    margin-left: 10px;

    &:first-child {
        margin-left: 0;
    }

    .svg {
        display: block;
        fill: ${colorGray};
        transition: all 0.5s ease;
        width: 33px;
        height: 33px;
        @media (${mediaMobile}) {
            width: 19px;
            height: 19px;
        }

        &:hover {
            fill: ${colorPrimary};
        }
    }
`;
