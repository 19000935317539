import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import { UserBio } from 'types';
import { ContentCard, Text, Name, Description, Flip, Follow } from './styled';
import { useTranslation } from 'react-i18next';
import { Icon } from 'kl-b2c-ui-kit';

export const UserBioBack: FC<UserBio> = ({ description, name, twitter }) => {
    const { t } = useTranslation('pages/home');

    return (
        <ContentCard>
            <Text>
                <Name>{name}</Name>
                <Description
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(description),
                    }}
                />
                <br />
                <br />
                {twitter && (
                    <Description overflow>
                        <Follow>
                            {t('follow')}{' '}
                            <a href={`https://x.com/${twitter}`} target={'_blank'} rel="noreferrer">
                                @{twitter}
                            </a>
                        </Follow>
                    </Description>
                )}

                {(twitter || description) && (
                    <Flip>
                        <span>{t('flip-less')}</span>
                        <Icon name={'Refresh'} size={18} color="teal" />
                    </Flip>
                )}
            </Text>
        </ContentCard>
    );
};
