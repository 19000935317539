import { ActionPageKey } from 'enums/action-page-key';
import { Normalize } from 'react-i18next';
import actionPage from '../../../../public/locales/en/pages/action-page.json';

export const PageConfig: Record<
    ActionPageKey,
    {
        title: Normalize<typeof actionPage>;
        image: string;
        descriptionTitle: Normalize<typeof actionPage>;
    }
> = {
    [ActionPageKey.Registration]: {
        image: '/assets/images/cocktail.png',
        title: 'reg-title',
        descriptionTitle: 'reg-desc-title',
    },
    [ActionPageKey.Papers]: {
        image: '/assets/images/bird.png',
        title: 'papers-title',
        descriptionTitle: 'papers-desc-title',
    },
    [ActionPageKey.Sponsor]: {
        image: '/assets/images/dog.png',
        title: 'sponsor-title',
        descriptionTitle: 'sponsor-desc-title',
    },
};
