import React from 'react';
import { Outlet } from 'react-router-dom';
import { headerMenuItemsEn, footerModel } from 'consts';
import Header from '../../components/header/Header';
import Main from '../../components/main/Main';
import Footer from '../../components/footer/Footer';
import BottomHeader from 'components/header/BottomHeader';

const App = () => (
    <>
        <Header links={headerMenuItemsEn} />
        <Main>
            <Outlet />
        </Main>
        <BottomHeader links={headerMenuItemsEn} />
        <Footer model={footerModel} />
    </>
);

export default App;
