import { listMixin } from 'components/accordion/styled';
import styled from 'styled-components';
import { mediaSm } from 'theme/theme-helper-selectors';

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    input,
    textarea {
        width: 100%;
    }

    textarea {
        height: 150px;
        resize: none;
    }
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
`;

export const Description = styled.div`
    p {
        font-size: 21px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.8);

        @media (${mediaSm}) {
            font-size: 16px;
            line-height: 18px;
        }

        &:not(:last-child) {
            margin-bottom: 32px;

            @media (${mediaSm}) {
                margin-bottom: 24px;
            }
        }

        a {
            margin: 16px 0 0 0;
            font-weight: 500;
            display: block;
            color: #3de8ca;
        }
    }
`;

export const Description2 = styled.div`
    p {
        font-size: 18px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.8);

        @media (${mediaSm}) {
            font-size: 14px;
            line-height: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;

            @media (${mediaSm}) {
                margin-bottom: 8px;
            }
        }

        a {
            font-weight: 500;
            color: #3de8ca;
        }

        ${listMixin}
    }
`;
