import React, { FC } from 'react';
import { CardDescription, CardList, CardListItem, CardListItemText, Discount, Pricing } from './styled';
import { useTranslation } from 'react-i18next';
import { Icon } from 'kl-b2c-ui-kit';

interface RegistrationPricingProps {
    noMargin?: boolean;
}

const RegistrationPricing: FC<RegistrationPricingProps> = ({ noMargin }) => {
    const { t } = useTranslation('pages/home');

    return (
        <>
            <CardDescription margin={24}>{t('attendee-includes')}</CardDescription>
            <CardList>
                <CardListItem>
                    <Icon name={'Check'} size={20} color={'teal'} />
                    <CardListItemText>
                        {t('opp-list-1')}
                        <br />
                        <span>{t('opp-list-1-desc')}</span>
                    </CardListItemText>
                </CardListItem>
                <CardListItem>
                    <Icon name={'Check'} size={20} color={'teal'} />
                    <CardListItemText>{t('opp-list-2')}</CardListItemText>
                </CardListItem>
                <CardListItem>
                    <Icon name={'Check'} size={20} color={'teal'} />
                    <CardListItemText>{t('opp-list-3')}</CardListItemText>
                </CardListItem>
                <CardListItem>
                    <Icon name={'Check'} size={20} color={'teal'} />
                    <CardListItemText>
                        {t('opp-list-4')}
                        <br />
                        <span>{t('opp-list-4-desc')}</span>
                    </CardListItemText>
                </CardListItem>
                <CardListItem>
                    <Icon name={'Check'} size={20} color={'teal'} />
                    <CardListItemText>
                        {t('opp-list-5')}
                        <br />
                        <span>{t('opp-list-5-desc')}</span>
                    </CardListItemText>
                </CardListItem>
            </CardList>
            <Pricing noMargin={noMargin}>
                <Discount>{t('opp-discount')}</Discount>
            </Pricing>
        </>
    );
};

export default RegistrationPricing;
