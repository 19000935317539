import styled, { css } from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from '../../theme/theme-helper-selectors';
import { NavList, NavListItem } from 'components/header/header-nav/styled';
import { NativeNavLink, RouterNavLink } from 'components/header/header-nav/header-link/styled';

export const HeaderRoot = styled.header<{ bottom?: boolean; menuOpened?: boolean; isSticky?: boolean }>`
    top: ${({ isSticky }) => (isSticky ? 20 : -90)}px;
    z-index: 1000;
    width: 100%;
    transition: top 0.3s ease-in-out;
    padding: ${({ isSticky }) => (isSticky ? '0' : '25px 0 0')};
    background: ${({ isSticky, theme }) => (isSticky ? 'transparent' : theme.palette.background)};
    position: ${({ isSticky }) => (isSticky ? 'fixed' : 'static')};

    ${({ bottom }) =>
        bottom &&
        css`
            ${NavListItem} {
                ${NativeNavLink}, ${RouterNavLink} {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
            ${HeaderWrapper} {
                padding: 0 64px 70px;

                @media (${mediaMd}) {
                    padding: 0 50px 30px;
                }

                @media (${mediaXs}) {
                    padding: 0 16px 10px;
                }
            }
        `}

    ${({ menuOpened }) =>
        menuOpened &&
        css`
            @media (${mediaSm}) {
                display: none;
            }
        `}

    @media (${mediaSm}) {
        position: static;
        ${({ bottom }) =>
            bottom &&
            css`
                padding-top: 30px;

                ${LogoWrapper} {
                    padding-top: 20px;
                }
                ${HeaderWrapper} {
                    align-items: flex-start;
                }

                ${NavList} {
                    width: 40%;
                    height: 140px;
                    padding: 0;
                    flex-direction: column;

                    ${NavListItem} {
                        width: auto;
                        min-height: 18px;
                        padding: 0 10px;
                        &:nth-of-type(1) {
                            order: 1;
                        }
                        &:nth-of-type(2) {
                            order: 2;
                        }
                        &:nth-of-type(3) {
                            order: 4;
                        }
                        &:nth-of-type(4) {
                            order: 3;
                        }
                        &:nth-of-type(5) {
                            order: 5;
                        }
                    }
                }
            `}
    }
    @media (${mediaXs}) {
        ${({ bottom }) =>
            bottom &&
            css`
                ${HeaderWrapper} {
                    flex-direction: column;
                }
                ${NavList} {
                    width: 100%;
                    height: auto;
                    ${NavListItem} {
                        padding: 0 30px 0 0;
                    }
                }
                ${LogoWrapper} {
                    order: 2;
                }
            `}
    }
`;

export const HeaderWrapper = styled.div<{ isSticky?: boolean }>`
    display: flex;
    align-items: center;
    padding: 7px 32px;
    border-radius: 24px;
    width: calc(100% - 64px);
    margin: 0 auto;
    backdrop-filter: ${({ isSticky }) => (isSticky ? 'blur(21px)' : 'none')};
    background: ${({ isSticky }) => (isSticky ? 'rgba(178, 178, 178, 0.14)' : 'transparent')};

    @media (${mediaSm}) {
        padding: 0 24px;
        justify-content: space-between;
    }

    @media (${mediaSm}) {
        padding: 0;
    }
`;

export const LogoWrapper = styled.div`
    flex-grow: 1;

    @media (${mediaSm}) {
        flex-grow: initial;
    }
`;
