import styled from 'styled-components';

export const DropdownTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
        content: '';
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin: 0 8px;
        transition: margin-top 100ms ease, transform 100ms ease;
        transform: rotate(45deg);
        opacity: 0.7;
    }
`;
