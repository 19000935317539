import React from 'react';
import {
    Bottom,
    ButtonBlock,
    CenterBlock,
    ComputerButton,
    Content,
    Description,
    DescriptionBlock2,
    DescriptionBlock3,
    Ellipse,
    FirstColumn,
    Left,
    Minus,
    Plus,
    Right,
    SecondColumn,
    SubTitle,
    SubTitleBlock,
    Title,
    Top,
    Vertical,
} from './styled';
import { useTranslation } from 'react-i18next';
import Screen from './Screen';
import DOMPurify from 'dompurify';

const Computer = () => {
    const { t } = useTranslation('pages/home');
    return (
        <CenterBlock>
            <Ellipse />
            <Top>
                <Plus />
                <Minus width={22} />
                <Title variant="h3">{t('event')}</Title>
                <Minus className="all" />
                <Plus />
            </Top>
            <Bottom>
                <Plus />
                <Minus className="all" />
                <ButtonBlock>
                    <ComputerButton onClick={() => window.open('https://ctf.thesascon.com/')}>
                        {t('details').toUpperCase()}
                    </ComputerButton>
                </ButtonBlock>
                <Minus width={125} />
                <Plus />
            </Bottom>
            <Left>
                <Vertical />
            </Left>
            <Right>
                <Vertical />
            </Right>
            <Content>
                <FirstColumn>
                    <SubTitleBlock>
                        <SubTitle>{t('ctf')}</SubTitle>
                    </SubTitleBlock>
                    <DescriptionBlock2>
                        <Description variant="p1" color="textDisabled">
                            {t('ctf-chapter2')}
                        </Description>
                    </DescriptionBlock2>
                    <DescriptionBlock3>
                        <Description variant="p1" color="textDisabled">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(t('ctf-chapter3')),
                                }}
                            />
                        </Description>
                    </DescriptionBlock3>
                </FirstColumn>
                <SecondColumn>
                    <Screen />
                </SecondColumn>
            </Content>
        </CenterBlock>
    );
};

export default Computer;
