import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colorText, mediaSm } from '../../../../theme/theme-helper-selectors';
import { gradientTextMixin } from 'styles/gradient-text-mixin';

const navLinkMixin = css`
    text-decoration: none;
    color: ${colorText};
    height: 100%;

    @media (${mediaSm}) {
        width: 100%;
    }

    &.active {
        ${gradientTextMixin}
    }
`;

export const NativeNavLink = styled.a`
    ${navLinkMixin}
`;

export const RouterNavLink = styled(NavLink)`
    ${navLinkMixin}
`;
