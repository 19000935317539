import React, { FC, useEffect, useState } from 'react';
import { FormCard, Title, Description, FieldsContainer, FormButton, FieldRow, Error } from './styled';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import FormBase from 'components/form-base/FormBase';
import { useForm, FormProvider, SubmitHandler, FieldValues, Controller } from 'react-hook-form';
import { SasSubmit } from 'types/sas-submit';
import { TextField, useUtmParams } from 'kl-b2c-ui-kit';
import { DefaultTheme, withTheme } from 'styled-components';
import { getNotificationOrderService } from 'services/api.factory';
import Success from 'containers/pages/coming-soon/Components/Success';
import Loading from 'containers/pages/coming-soon/Components/Loading';
import { EMAIL_PATTERN } from 'consts';

const UpdatesForm: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const { t } = useTranslation(['pages/home', 'pages/coming-soon', 'validations']);
    const [loading, setLoading] = useState<boolean>(false);
    const notificationOrderService = getNotificationOrderService();
    const [success, setSuccess] = useState(false);

    const methods = useForm<SasSubmit>();

    const {
        control,
        formState: { errors },
    } = methods;

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        const utm = useUtmParams();

        const requestData: SasSubmit = {
            email: data.email,
            captchaCode: data.captchaCode,
            sendMessage: false,
            agreement: {
                marketingIsSelected: true,
                marketingCheckBoxText: t('pages/coming-soon:agreements'),
            },
            ...utm,
        };

        await notificationOrderService.addApplication(requestData);
        setLoading(false);
        setSuccess(true);
    };

    useEffect(() => {
        if (success || loading) {
            document.body.classList.add('is-modal-open');
        } else {
            document.body.classList.remove('is-modal-open');
        }
    }, [success, loading]);

    return (
        <>
            <FormCard>
                <Title variant={'h3'} fontWeight={'regular'} color={'textSecondary'}>
                    {t('pages/home:updates')}
                </Title>
                <Description variant={'p2'} fontWeight={'light'} color={'textSecondary'}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(t('pages/home:updates-desc')),
                        }}
                    />
                </Description>
                <FormProvider {...methods}>
                    <FormBase
                        type={'secondary'}
                        gap={'24px'}
                        policies={[
                            {
                                name: 'agreement.marketingCheckBoxText',
                                label: t('pages/coming-soon:agreements'),
                                required: true,
                            },
                        ]}
                        submit={onSubmit}
                        hideSubmitBtn
                        loading={loading}
                    >
                        <FieldsContainer>
                            <Controller
                                name={'email'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('validations:fieldIsRequired'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: t('validations:fieldIsTooLong', {
                                            length: 255,
                                        }),
                                    },
                                    pattern: {
                                        value: EMAIL_PATTERN,
                                        message: t('validations:emailHasNotCorrectFormat'),
                                    },
                                }}
                                render={({ field: { onChange } }) => (
                                    <FieldRow>
                                        <TextField
                                            type={'text'}
                                            variant={'border'}
                                            transparent
                                            color={theme.palette.text.secondary}
                                            placeholder={t('pages/coming-soon:email-placeholder')}
                                            onChange={(value) => onChange(value)}
                                            error={!!errors['email']}
                                        />
                                        {errors?.email && (
                                            <Error variant="p5">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            errors?.email?.message ??
                                                            DOMPurify.sanitize(t('validations:default')),
                                                    }}
                                                />
                                            </Error>
                                        )}
                                    </FieldRow>
                                )}
                            />
                            <FormButton color={'primary-contrast'} type={'submit'}>
                                {t('pages/coming-soon:subscribe')}
                            </FormButton>
                        </FieldsContainer>
                    </FormBase>
                </FormProvider>
            </FormCard>
            {loading && <Loading label={t('pages/coming-soon:data-sending')} />}
            {success && <Success close={() => setSuccess(false)} />}
        </>
    );
};

export default withTheme(UpdatesForm);
