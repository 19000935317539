import { IApiService, IConfigApi, AppConfig, HttpResponse } from 'types';

export class ConfigApi implements IConfigApi {
    constructor(private readonly apiService: IApiService) {}

    public getConfig(): Promise<HttpResponse<AppConfig>> {
        return this.apiService.request<AppConfig>({
            method: 'GET',
            url: '/siteSettings/get',
        });
    }
}
