import styled from 'styled-components';
import { mediaSm, mediaXs, transitionBase } from '../../../../theme/theme-helper-selectors';

export const Nav = styled.nav<{ isHidden: boolean }>`
    transition: ${transitionBase};

    @media (${mediaSm}) {
        width: 100%;
        right: 0;
        bottom: 0;
        position: absolute;
        top: 89px;
        z-index: 100;
        padding: 0;
        background: ${({ theme }) => theme.palette.background};
        display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
    }

    @media (${mediaXs}) {
        width: 100%;
    }
`;
