import styled from 'styled-components';
import { Card } from 'components/card/styled';
import { mediaLg, mediaMd, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const ContentCard = styled(Card)`
    height: 100%;
    padding-bottom: 72px;
    overflow: hidden;
`;

export const ImageContainer = styled.div<{ background: string }>`
    height: 428px;
    background-image: ${({ background }) => `url(${background})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin: -48px -48px 40px -48px;

    @media (${mediaLg}) {
        height: 309px;
    }

    @media (${mediaMd}) {
        margin: -32px -32px 40px -32px;
        height: 292px;
    }

    @media (${mediaSm}) {
        height: 321px;
    }

    @media (${mediaXs}) {
        height: 343px;
    }
`;

export const Name = styled.div`
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;
    font-weight: 500;

    @media (${mediaSm}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const Position = styled.div`
    font-size: 18px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 28px 0;
`;

export const Flip = styled.div`
    color: ${({ theme }) => theme.palette.teal};
    font-size: 21px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    position: absolute;
    bottom: 48px;
`;

export const Text = styled.div`
    text-align: left;
`;

export const Description = styled.div<{ overflow?: boolean }>`
    font-size: 21px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
    overflow-y: ${({ overflow }) => (overflow ? 'hidden' : 'auto')};
    overflow-x: hidden;
    max-height: 450px;

    ul {
        list-style-type: disc;
        padding: 0 0 0 40px;
    }

    @media (${mediaLg}) {
        max-height: 280px;
    }

    @media (${mediaSm}) {
        max-height: 310px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border: 5px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;
    }
    &::-webkit-scrollbar {
        width: 16px;
    }
`;

export const Follow = styled.span`
    color: ${({ theme }) => theme.palette.white};
    font-weight: 500;

    a {
        word-break: break-all;
        overflow: hidden;
    }
`;

export const TalkContainer = styled.div`
    margin: 0 0 28px 0;
`;

export const Talk = styled.span<{ isGreen?: boolean }>`
    font-size: 24px;
    line-height: 27px;
    color: ${({ isGreen, theme }) => (isGreen ? theme.palette.teal : 'rgba(255, 255, 255, 0.6)')};
    font-weight: ${({ isGreen }) => (isGreen ? 500 : 400)};
`;
