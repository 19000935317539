import styled from 'styled-components';
import {
    colorBackground,
    colorText,
    headerHeight,
    mediaMd,
    mediaSm,
    mediaXs,
    transitionBase,
} from '../../../../theme/theme-helper-selectors';
import { NativeNavLink, RouterNavLink } from '../header-link/styled';
import { DropdownContent } from '../../header-menu-dropdown/styled';
import { gradientTextMixin } from 'styles/gradient-text-mixin';

export const NavListItem = styled.li`
    display: flex;
    align-items: center;
    color: ${colorText};
    margin: 0 10px;

    @media (${mediaMd}) {
        margin: 0 6px;
    }

    @media (${mediaSm}) {
        &,
        ${DropdownContent} {
            margin: 0;
            width: 100%;
            border-bottom: 2px solid ${colorBackground};
            min-height: ${headerHeight};
            padding: 0 32px;
        }
    }
    @media (${mediaXs}) {
        &,
        ${DropdownContent} {
            padding: 0 24px;
        }
    }

    ${NativeNavLink}, ${RouterNavLink} {
        padding: 12px 8px;
        font-size: 21px;
        line-height: 24px;
        font-weight: normal;
        transition: ${transitionBase};
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-weight: 500;

        @media (${mediaSm}) {
            border-width: 0;
            font-size: 24px;
            line-height: 27px;
            font-weight: 500;

            a {
                padding: 0;
            }
        }
    }

    ${NativeNavLink}, ${RouterNavLink} {
        height: 100%;

        &:hover {
            ${gradientTextMixin}
        }

        @media (${mediaSm}) {
            width: 100%;
        }
    }
`;
