import styled, { css } from 'styled-components';
import { MobileWrap } from './mobile-wrap';
import {
    colorBackground,
    headerHeight,
    mediaSm,
    mediaXs,
    transitionBase,
} from '../../../../theme/theme-helper-selectors';

const collapseMixin = css`
    overflow: hidden;
    height: 0;

    ${MobileWrap} {
        max-height: 0;
    }
`;

const expandMixin = css`
    @media (${mediaXs}) {
        height: calc(100vh - ${headerHeight});
    }
`;

export const NavBarCollapse = styled.div<{ collapse: boolean }>`
    @media (${mediaSm}) {
        transition: ${transitionBase};
        background-color: ${colorBackground};

        ${({ collapse }) => (collapse ? collapseMixin : expandMixin)}
    }
`;
