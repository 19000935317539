import React, { Context, createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface MobileMenuState {
    isMobileMenuOpened: boolean;
    toggleMobileMenu: (opened: boolean) => void;
}

const initState: MobileMenuState = {
    isMobileMenuOpened: false,
    toggleMobileMenu: () => {},
};

const MobileMenuContext: Context<MobileMenuState> = createContext(initState);

export const MobileMenuStateProvider: FC<{ children: ReactNode }> = (props) => {
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(initState.isMobileMenuOpened);
    const location = useLocation();

    const toggleMobileMenu = useCallback((opened: boolean) => {
        setIsMobileMenuOpened(opened);
    }, []);

    useEffect(() => {
        isMobileMenuOpened && toggleMobileMenu(false);
    }, [location]);

    useEffect(() => {
        const openMenuClass = 'is-header-menu-open';
        isMobileMenuOpened ? document.body.classList.add(openMenuClass) : document.body.classList.remove(openMenuClass);
    }, [isMobileMenuOpened]);

    return (
        <MobileMenuContext.Provider
            value={{
                toggleMobileMenu,
                isMobileMenuOpened,
            }}
            {...props}
        />
    );
};

export const useMobileMenuContext = () => useContext(MobileMenuContext);
