import styled from 'styled-components';
import { mediaSm, mediaXs } from '../../../theme/theme-helper-selectors';

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    @media (${mediaSm}) {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 10px;
        order: 2;
    }

    @media (${mediaXs}) {
        flex-direction: column;
    }
`;
