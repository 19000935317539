export const FAQ_TAGS = ['general', 'speaker', 'sponsor', 'ctf'];
export const TOPICS_LIST =
    '<ul><li>Advanced cyberthreats, APT actors and cyberwarfare</li><li>Critical infrastructure and ICS/OT security</li><li>IoT attacks and security</li><li>Supply chain attacks and open-source software security</li><li>Ransomware incidents and how to stay protected</li><li>Zero-day vulnerabilities and exploits</li><li>Dark web trends and analytics</li><li>Artificial intelligence, machine learning and cybersecurity</li></ul><p>This year we want to expand cybersecurity discussions to galaxies far, far away, so we would be particularly interested to see applications about cyber-hacks in space, attacks on space infrastructure, space probes, sensors, satellites, etc. </p>';
export const FAQ_ACCORDION = [
    {
        key: 'what',
        tags: ['general'],
        question: 'What is SAS?',
        answer:
            '<p>The Security Analyst Summit is an exclusive, invitation-only three-day conference for leading anti-malware researchers, global law enforcement, CERTs and senior executives from financial services, technology, healthcare, academia and government agency backgrounds. </p>' +
            '<p>The conference provides an exclusive atmosphere that encourages debate, information sharing and displays of cutting-edge research and new technologies, as participants explore ways to improve collaboration in the fight against cybercrime.</p>',
    },
    {
        key: 'why',
        tags: ['general'],
        question: 'Why should I join?',
        answer:
            '<p>At the conference, speakers share exclusive talks and insights for the first time; for instance, by joining, you will be among the first to hear about recent APT discoveries! ' +
            '<p>SAS boasts lively and laid-back ambiance in equal measure. Here, in-depth research topics intersect with countless opportunities to network and share knowledge. As we love to say: work hard, play hard! We know how to break the ice and create a close-knit community of professional relationships that last a lifetime.</p>',
    },
    {
        key: 'safe',
        tags: ['general'],
        question: 'Is it safe to join a conference?',
        answer:
            '<p>Absolutely - more than 800  SAS participants since first SAS participants over 15 events can’t be wrong! As a global private cybersecurity company, Kaspersky is valued as a trusted industry partner worldwide and, over many years, it has established ongoing support and collaboration with numerous international organizations and initiatives, continuing to strengthen global security... </p>' +
            '<p>International visibility and collaboration around cybersecurity are core values of SAS, and we believe that not only are cyber threats not confined by borders, they are a global concern that requires a coordinated, international response. </p>' +
            "<p>And you can rest assured that we treat the safety and comfort of our guests as our number one priority; we are working hard - in both the physical and cyber spaces - to create a wonderful event where knowledge can be shared freely in an agenda-free environment. You can learn more about our principles in our <a href='/code' >Code of Conduct</a>.</p>",
    },
    // {
    //     key: 'participate',
    //     tags: ['general'],
    //     question: 'How can I participate at SAS as an attendee?',
    //     answer: '<p>You will need to fill in our application form <a href="/registration" target="_blank" rel="noopener noreferrer">here</a>. SAS is an invitation-only conference and we value our unique atmosphere and close-knit community. Please fill in the form, which we will review and get back to you with instructions on how to finish your registration and buy a ticket to attend. </p>',
    // },
    {
        key: 'included',
        tags: ['general'],
        question: 'What is included in the attendee package? ',
        answer: '<p>Your package includes access to the entire SAS conference, accommodation from October 22-25 and all meals and activities within the program: Arrival day Welcome Dinner, Gala Dinner following the keynote day, our activity program and Farewell dinner. </p>',
    },
    // {
    //     key: 'become',
    //     tags: ['speaker'],
    //     question: 'How do I become a speaker at SAS?',
    //     answer: '<p>Please fill in <a href="/papers" target="_blank" rel="noopener noreferrer">this special form</a>, providing the topic and abstract of your presentation. Our Call for Papers and submission process ends August 15, 2024, when our SAS program committee will carefully review all submissions and choose the most promising and unique topics.</p>',
    // },
    {
        key: 'package',
        tags: ['speaker'],
        question: 'What does the speaker’s package include?',
        answer: '<p>If you are chosen to join us at SAS, we’ll cover your ticket to Bali (Economy class) and accommodation from October 21-25 (allowing one additional day for rehearsal), as well as the whole event program, all dinners and activities. </p>',
    },
    {
        key: 'topics-covered',
        tags: ['speaker'],
        question: 'What topics will be covered at SAS? ',
        answer: '<p>This year, our focus is on: </p>' + TOPICS_LIST,
    },
    {
        key: 'topics',
        tags: ['speaker'],
        question: 'Are there any topics that can’t be covered at SAS? ',
        answer: "<p>We welcome a wide variety of presentation topics with minimal restrictions. However, please be aware that our program committee prioritizes technical research and impartial content. Consequently, we must respectfully decline any presentations perceived as politically motivated or manipulative, or those primarily aimed at marketing or product promotion. For those looking to promote a product or service, we provide a range of tailored <a href='/files/SAS_2024.pdf' download>sponsorship opportunities.</a></p>",
    },
    {
        key: 'format',
        tags: ['speaker'],
        question: 'What is the format of the presentations? ',
        answer:
            '<p>There two types: </p>' +
            '<ul><li>Classic: A 20-minute presentation (including time for questions). If you feel that your topic needs additional time to present, please leave us a note and we will discuss how this can be accommodated within the timing of the conference. </li><li>Pecha-kucha: A storytelling format in which a presenter shows 20 slides with 20 seconds of commentary for each. Therefore, the duration of each presentation is limited to 400 seconds max. We recommend this format if you have interesting thoughts or ideas you would like to pitch in a more engaging way. Also, this can be a great personal challenge/achievement for you as a speaker. Plus, we identify the best Pecha-Kucha speaker after all the talks in this format via audience voting for added incentive!</li></ul>',
    },
    // {
    //     key: 'become-sponsor',
    //     tags: ['sponsor'],
    //     question: 'How do I become a sponsor at SAS? ',
    //     answer:
    //         '<p>We have several options of partnership with the conference: three standard packages and one very special Merch sponsor. Discover more in our sponsorship brochure! </p>' +
    //         "<p><a href='/files/SAS_2024.pdf' download>Download the SAS sponsorship brochure</a> </p>" +
    //         '<p>Interested? Please fill in <a href="/sponsors" target="_blank" rel="noopener noreferrer">the form</a>, and we will get back to you with additional details. </p>',
    // },
    {
        key: 'why-sponsor',
        tags: ['sponsor'],
        question: 'Why should we sponsor SAS?',
        answer:
            '<p>This is a unique opportunity to gain visibility – both at the conference and far beyond – among subject matter experts, cyber security influencers and key decision makers in one of the most topical and fastest-growing industries! </p>' +
            '<p>SAS 2024 provides multiple promotional opportunities across the conference venue, as well as the opportunity to feature prominently in SAS publicity campaigns through media partners.</p>',
    },
    {
        key: 'broadcast',
        tags: ['general'],
        question: 'Will you have an online broadcast?',
        answer: '<p>No, we and our guests value greatly the offline experience of the SAS - no additional prying eyes! However, we are open for knowledge sharing afterwards and provide recordings of all the presentations where our speakers have given their approval. </p>',
    },
    {
        key: 'what-is-ctf',
        tags: ['ctf'],
        question: 'What is SAS CTF? ',
        answer:
            '<p>This year, we are holding the SAS CTF - an international competition for cybersecurity experts.</p>' +
            '<p>The competition consists of an online Jeopardy qualification stage and on-site A/D finals, taking place at the SAS conference in Bali, Indonesia October 22-25, 2024.</p>' +
            '<p>Our SAS CTF 2024 Finals prize pool will be $18.000. You can also contribute to CTF community by becoming a SAS CTF partner. Partnership fee (starting from $5000) will go straight to our CTF community; all SAS CTF Partner funds are pooled together to make our prize pool for SAS CTF even bigger.</p>' +
            '<p>Learn more details at the <a href="https://ctf.thesascon.com/" target="_blank">SAS CTF web-site</a>.</p>',
    },
    {
        key: 'invite',
        tags: ['general'],
        question: 'Can I invite additional guests to join me at SAS?',
        answer:
            '<p>If you would like to invite a peer or colleague from the industry to attend the entire conference program – please fill in an additional attendee form. In the event that we approve their application to attend, they will need to buy an additional ticket. </p>' +
            '<p>If you would like to bring along your “plus one” to Welcome Dinner, Gala Dinner and our activity program, there will be a possibility to buy tickets to these informal events separately, during your registration and payment process. Please be aware that the number of additional seats is limited. </p>',
    },
    {
        key: 'journalist',
        tags: ['general'],
        question: "I'm a journalist. Can I register for the event? ",
        answer: '<p>For media/press accreditation, please contact <a  href="mailto:thesascon@thesascon.com">thesascon@thesascon.com</a></p>',
    },
];
