import React, { FC, useLayoutEffect, useState } from 'react';
// import { NavLink } from 'react-router-dom';
import {
    Container,
    Text,
    Date,
    DateItem,
    // ButtonContent,
    // ButtonIconContainer,
    // ButtonLabel,
    // IntroButton,
    // ButtonsContainer,
    DateItems,
    IntroRoot,
} from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Globe } from '../../../../../assets/icons/sas-globe.svg';
// import { Icon } from 'kl-b2c-ui-kit';
// import DOMPurify from 'dompurify';

const Intro: FC = () => {
    const { t } = useTranslation('pages/home');
    const [parallaxValue, setParallaxValue] = useState<number>(0);

    useLayoutEffect(() => {
        const updatePosition = () => {
            setParallaxValue(window.pageYOffset / 2);
        };
        window.addEventListener('scroll', updatePosition);
        updatePosition();
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    return (
        <IntroRoot
            style={{
                transform: `translateX(0px) translateY(${parallaxValue}px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0px)`,
            }}
        >
            <Container>
                <Text>{t('kaspersky')}</Text>
                <Globe className={'globe'} />
                <Text>{t('security')}</Text>
                <Text>{t('analyst-summit')}</Text>
                {/*@ts-ignore*/}
                {/* <IntroButton type={'outlined'} size={'large'}>
                    <NavLink to={'/papers'}>
                        <ButtonContent>
                            <ButtonLabel dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('papers')) }} />
                            <ButtonIconContainer>
                                <Icon name={'ArrowDiagonale'} size={21} color={'black'} />
                            </ButtonIconContainer>
                        </ButtonContent>
                    </NavLink>
                </IntroButton> */}
                <Text outlined>{t('bali')}</Text>
                <Date>
                    <Globe />
                    <DateItems>
                        <DateItem>{t('date-month')}</DateItem>
                        <DateItem>{t('date-year')}</DateItem>
                    </DateItems>
                </Date>
            </Container>
            {/* <ButtonsContainer>
                <IntroButton type={'contained'} size={'large'}>
                    <NavLink to={'/registration'}>
                        <ButtonContent>
                            <ButtonLabel dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('register')) }} />
                            <ButtonIconContainer>
                                <Icon name={'ArrowDiagonale'} size={21} color={'black'} />
                            </ButtonIconContainer>
                        </ButtonContent>
                    </NavLink>
                </IntroButton>
                <IntroButton type={'transparent'} size={'large'}>
                    <NavLink to={'/sponsors'}>
                        <ButtonContent>
                            <ButtonLabel dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('sponsor')) }} />
                            <ButtonIconContainer>
                                <Icon name={'ArrowDiagonale'} size={21} color={'black'} />
                            </ButtonIconContainer>
                        </ButtonContent>
                    </NavLink>
                </IntroButton>
            </ButtonsContainer> */}
        </IntroRoot>
    );
};

export default Intro;
