import styled from 'styled-components';
import { mediaLg, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';

export const FaqContainer = styled.div`
    margin: 0 0 140px 0;

    @media (${mediaLg}) {
        margin: 0 0 65px 0;
    }

    @media (${mediaSm}) {
        margin: 0 0 100px 0;
    }

    @media (${mediaXs}) {
        margin: 0 0 50px 0;
    }
`;

export const BlockTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 40px 0;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (${mediaSm}) {
        flex-direction: column;
    }
`;

export const Column = styled.div<{ width: string }>`
    width: ${({ width }) => width};

    @media (${mediaSm}) {
        width: 100%;
    }
`;
