import styled, { css } from 'styled-components';
import { mediaLg, mediaMd, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';
import { Tag } from 'components/tags/styled';
import { Card } from 'components/card/styled';

export const BlockTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 75px 0;

    @media (${mediaSm}) {
        margin: 0 0 55px 0;
    }
`;

export const ProgramsContainer = styled.div`
    margin: 0 0 160px 0;

    @media (${mediaLg}) {
        margin: 0 0 120px 0;
    }

    @media (${mediaMd}) {
        margin: 0 0 100px 0;
    }
`;

export const DatesContent = styled(Card)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    @media (${mediaLg}) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;
export const DatesTagContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (${mediaLg}) {
        padding-top: 24px;
    }
`;
export const DatesTag = styled(Tag)`
    padding: 24px 32px 24px 32px;
    border-radius: 64px;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    ${({ active }) =>
        !active &&
        css`
            background: none;
        `}
    @media (${mediaSm}) {
        font-size: 21px;
        line-height: 24px;
        padding: 16px;
    }
    @media (${mediaXs}) {
        font-size: 32px;
        line-height: 36px;
        padding: 16px;
        border-radius: 8px;
        padding: 8px;
        font-weight: 500;
    }
`;

export const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (${mediaXs}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const DatesNumber = styled.span`
    display: flex;
`;
export const DatesMonth = styled.span`
    display: flex;
    padding-left: 8px;
    @media (${mediaXs}) {
        padding-left: 0px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
    }
`;
export const CurrentDate = styled.h3`
    color: ${({ theme }) => theme.palette.white};
    font-size: 54px;
    font-weight: 500;
    line-height: 54px;
    flex: 1;
    padding-right: 10px;
    @media (${mediaMd}) {
        font-size: 42px;
        line-height: 42px;
    }
    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 36px;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const DayOfWeek = styled.span`
    @media (${mediaXs}) {
        color: rgba(255, 255, 255, 0.6);
    }
`;
export const MonthNumber = styled.span`
    padding-left: 8px;
    @media (${mediaXs}) {
        padding-left: 0px;
    }
`;

export const SessionsBlock = styled.div`
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
