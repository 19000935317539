import { CreateThemeInput } from 'kl-b2c-ui-kit';

export const light: CreateThemeInput = {
    palette: {
        titleHighlight: '#96dfa5',
        headerBackground: '#191918',
        background: '#191918',
        menuBackground: '#191918',
        text: {
            secondary: '#191918',
            primary: '#fff',
            disabled: 'rgba(255, 255, 255, 0.6)',
        },
        black: '#191918',
        white: '#fff',
        teal: '#29CCB1',
        gray: {
            100: '#5A5A5A',
            200: '#666666',
            300: '#999999',
            400: '#aeaeae',
            500: '#cccccc',
            600: '#f2f2f2',
            700: '#f8f8f8',
        },
        precisionBlue: '#4AF4FF',
        green: 'linear-gradient(90deg, #29C2CC 0%, #4AFFE9 100%)',
    },
    shadows: {
        dropdown: '0 8px 16px 0px rgba(0, 0, 0, 0.2)',
    },
    breakpoints: {
        values: {
            xs: 767,
            sm: 1279,
            md: 1439,
        },
    },
    media: {
        xs: 'max-width: 767px',
        sm: 'max-width: 1023px',
        md: 'max-width: 1439px',
        lg: 'max-width: 1919px',
        mobile: 'min-width: 768px',
        tablet: 'min-width: 1024px',
        bigTablet: 'min-width: 1440px',
        superBigTablet: 'min-width: 1920px',
    },
    commonTypography: {
        fontFamily: 'Kaspersky Sans Display',
    },
    shape: {
        headerHeight: '64px',
        borderRadius: {
            button: 48,
            checkbox: 4,
            textField: 8,
        },
        borderWidth: {
            textField: 1,
            checkbox: 1,
            loader: 5,
        },
        baseSpacing: 2,
        checkbox: {
            baseSpacing: 4,
        },
        textField: {
            padding: 12,
        },
    },
    animation: {
        base: 'all 0.5s ease',
    },
    typography: {
        h1: {
            fontSize: 90,
        },
        h2: {
            fontSize: 54,
        },
        h3: {
            fontSize: 48,
        },
        subH4: {
            fontSize: 24,
        },
        p1: {
            fontSize: 24,
            lineHeight: 24,
        },
        textField: {
            fontSize: 14,
        },
    },
};
