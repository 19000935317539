import Loading from 'containers/pages/coming-soon/Components/Loading';
import Success from 'containers/pages/coming-soon/Components/Success';
import { ActionPageKey } from 'enums/action-page-key';
import { useUtmParams } from 'kl-b2c-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getActionsApiService, getApiService, getStorageService } from 'services/api.factory';
import { Participant } from 'types/participant';
import RegistrationDescription from './components/RegistrationDescription';
import RegistrationForm from './components/RegistrationForm';
import SpeakerDescription from './components/SpeakerDescription';
import SponsorDescription from './components/SponsorDescription';
import { PageConfig } from './config-mapper';
import { HttpResponse } from 'types';
import Error from 'containers/pages/coming-soon/Components/Error';

import {
    CardImage,
    Column,
    ColumnWrapper,
    Container,
    DescriptionContainer,
    DescriptionTitle,
    PageTitle,
} from './styled';
import { useLocation } from 'react-router-dom';
import { DefaultTheme, withTheme } from 'styled-components';

export const DEFAULT_FORM_STATE: Omit<Participant, 'captchaCode'> = {
    firstName: '',
    lastName: '',
    topic: '',
    comments: '',
    company: '',
    email: '',
    phone: '',
    position: '',
    file: null,
    agreement: {
        marketingIsSelected: false,
        privacyPolicyIsSelected: false,
    },
};

type ActionPageProps = {
    pageKey: ActionPageKey;
    theme: DefaultTheme;
};

const ActionPage: FC<ActionPageProps> = ({ pageKey, theme }) => {
    const config = PageConfig[pageKey];
    const { t } = useTranslation(['pages/action-page', 'pages/coming-soon']);
    const storageService = getStorageService();
    const apiService = getApiService(storageService);
    const actionsApiService = getActionsApiService(apiService);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);
    const [generalError, setGeneralError] = useState<{ title: string; description: string } | null>(null);

    const location = useLocation();

    useEffect(() => {
        reset(DEFAULT_FORM_STATE);
        clearErrors();
    }, [location]);

    const methods = useForm<Participant>();
    const { reset, clearErrors } = methods;

    const getPPmessage = (actionPageKey: ActionPageKey): string => {
        switch (actionPageKey) {
            case ActionPageKey.Papers:
                return t('pages/action-page:pp-papers');
            case ActionPageKey.Sponsor:
                return t('pages/action-page:pp-sponsor');

            default:
                return t('pages/action-page:pp');
        }
    };

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoading(true);
        const utm = useUtmParams();

        try {
            const requestData: Participant = {
                ...(data as Participant),
                agreement: {
                    marketingIsSelected: data.agreement.marketingIsSelected || false,
                    marketingCheckBoxText: t('pages/action-page:marketing'),
                    privacyPolicyIsSelected: true,
                    privacyPolicyCheckBoxText: getPPmessage(pageKey),
                },
                ...utm,
            };
            let response: HttpResponse<void>;
            switch (pageKey) {
                case ActionPageKey.Registration: {
                    response = await actionsApiService.register(requestData);
                    break;
                }
                case ActionPageKey.Sponsor: {
                    response = await actionsApiService.sponsor(requestData);
                    break;
                }
                case ActionPageKey.Papers: {
                    response = await actionsApiService.papers(requestData);
                    break;
                }
            }

            setLoading(false);
            if (response.status === 200) {
                setSuccess(true);
                reset(DEFAULT_FORM_STATE);
                clearErrors();
            } else if (response.status === 207) {
                setGeneralError({
                    description: t('pages/action-page:error-207'),
                    title: t('pages/action-page:interest'),
                });
            } else {
                setGeneralError({
                    description: t('pages/action-page:something-wrong'),
                    title: t('pages/action-page:oops'),
                });
            }
        } catch (error) {
            setGeneralError({
                description: t('pages/action-page:something-wrong'),
                title: t('pages/action-page:oops'),
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        if (success || loading || generalError) {
            document.body.classList.add('is-modal-open');
        } else {
            document.body.classList.remove('is-modal-open');
        }
    }, [success, loading, generalError]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <PageTitle variant={'h1'} fontWeight={'regular'}>
                {t(`pages/action-page:${config.title}`)}
            </PageTitle>
            <ColumnWrapper>
                <Column flex width={pageKey !== ActionPageKey.Papers ? 658 : 1180}>
                    <CardImage src={config.image} alt="Page image" />

                    <DescriptionContainer>
                        <DescriptionTitle variant={'h2'} fontWeight={'regular'}>
                            {t(`pages/action-page:${config.descriptionTitle}`)}
                        </DescriptionTitle>

                        {(() => {
                            switch (pageKey) {
                                case ActionPageKey.Registration:
                                    return <RegistrationDescription />;
                                case ActionPageKey.Sponsor:
                                    return <SponsorDescription />;
                                case ActionPageKey.Papers:
                                    return <SpeakerDescription />;
                            }
                        })()}
                    </DescriptionContainer>
                </Column>

                {(() => {
                    if (pageKey !== ActionPageKey.Papers) {
                        return (
                            <Column>
                                <FormProvider {...methods}>
                                    <RegistrationForm
                                        actionPageKey={pageKey}
                                        hidePosition={pageKey === ActionPageKey.Sponsor}
                                        submit={onSubmit}
                                        loading={loading}
                                    />
                                </FormProvider>
                            </Column>
                        );
                    }
                })()}
            </ColumnWrapper>
            {loading && <Loading label={t('pages/coming-soon:data-sending')} />}
            {success && (
                <Success
                    color={theme.palette.text.secondary}
                    gradient={'linear-gradient(90deg, #29C2CC 0%, #4AFFE9 100%)'}
                    close={() => setSuccess(false)}
                    gap={0}
                />
            )}
            {generalError && (
                <Error
                    gap={0}
                    close={() => setGeneralError(null)}
                    title={generalError?.title}
                    text={generalError?.description}
                />
            )}
        </Container>
    );
};

export default withTheme(ActionPage);
