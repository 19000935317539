import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getApiService, getConfigApiService } from 'services';
import { AppConfig, HttpResponse } from 'types';
import { getStorageService } from 'services/api.factory';

interface ConfigContext {
    appConfig: AppConfig | null;
}

const ConfigContext = createContext<ConfigContext>({} as ConfigContext);

const ConfigProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [appConfig, setAppConfig] = useState<AppConfig | null>(null);
    const storageService = getStorageService();
    const apiService = getApiService(storageService);
    const configApiService = getConfigApiService(apiService);

    useEffect(() => {
        configApiService
            .getConfig()
            .then((config: HttpResponse<AppConfig>) => {
                setAppConfig(config.data);
            })
            .catch((e: Error) => {
                console.warn(`Cannot load app settings: ${e.message}`);
            });
    }, []);

    const memoValue = useMemo(
        () => ({
            appConfig,
        }),
        [appConfig]
    );

    return <ConfigContext.Provider value={memoValue}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);

export default ConfigProvider;
