import React, { FC } from 'react';
import { Description } from './styled';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import RegistrationPricing from 'containers/pages/home-page/components/opportunities/RegistrationPricing';

const RegistrationDescription: FC = () => {
    const { t } = useTranslation('pages/action-page');

    return (
        <Description>
            <p
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t('reg-description')),
                }}
            />
            <RegistrationPricing noMargin />
        </Description>
    );
};

export default RegistrationDescription;
