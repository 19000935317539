import styled, { css } from 'styled-components';
import { PrimaryButton } from 'components/button/styled';

export const TagsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 48px 0;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Tag = styled(PrimaryButton)<{ active: boolean }>`
    padding: 8px 20px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    color: #383f48;
    height: auto;

    ${({ active }) =>
        !active &&
        css`
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.6);
        `}
`;
