import React, { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from '../app/App';
import { MobileMenuStateProvider, useConfig } from 'contexts';
import HomePage from 'containers/pages/home-page/HomePage';
import { Loader } from 'kl-b2c-ui-kit';
import { DefaultTheme, withTheme } from 'styled-components';
// import { ActionPageKey } from 'enums/action-page-key';

const ComingSoon = lazy(() => import('../pages/coming-soon/ComingSoon'));
const AboutPage = lazy(() => import('../pages/coming-soon/ComingSoon'));
const CodeOfConduct = lazy(() => import('../pages/code-of-conduct/CodeOfConduct'));
const Location = lazy(() => import('../pages/location/Location'));
// const ActionPage = lazy(() => import('../pages/action-page/ActionPage'));
const NotFoundPage = lazy(() => import('../../components/not-found-page/NotFoundPage'));

interface Props {
    theme: DefaultTheme;
}

const AppRouter: FC<Props> = ({ theme }) => {
    const { appConfig } = useConfig();

    if (!appConfig) return null;

    return (
        <BrowserRouter>
            <MobileMenuStateProvider>
                <Suspense
                    fallback={<Loader variant="fixed" size="large" transparent customColor={theme.palette.teal} />}
                >
                    <Routes>
                        {appConfig && appConfig.isOpened ? (
                            <>
                                <Route path="/" element={<App />}>
                                    <Route path="/" element={<HomePage />} />
                                    <Route path="about" element={<AboutPage />} />
                                    <Route path="code" element={<CodeOfConduct />} />
                                    <Route path="location" element={<Location />} />
                                    {/* <Route
                                        path="registration"
                                        element={<ActionPage pageKey={ActionPageKey.Registration} />}
                                    />
                                    <Route path="papers" element={<ActionPage pageKey={ActionPageKey.Papers} />} />
                                    <Route path="sponsors" element={<ActionPage pageKey={ActionPageKey.Sponsor} />} /> */}
                                    <Route path="*" element={<NotFoundPage />} />
                                </Route>
                            </>
                        ) : (
                            <>
                                <Route path="/" element={<ComingSoon />} />
                                <Route path="*" element={<ComingSoon />} />
                            </>
                        )}
                    </Routes>
                </Suspense>
            </MobileMenuStateProvider>
        </BrowserRouter>
    );
};

export default withTheme(AppRouter);
