import React, { Context, createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Theme } from '../types/enums/theme.enum';
import { ThemeProvider } from 'kl-b2c-ui-kit';
import { themes } from '../theme';

interface ThemeState {
    theme: Theme;
    toggleTheme: () => void;
}

const getInitTheme = (): Theme => {
    let theme = localStorage.getItem('theme') as Theme;

    if (!theme) {
        theme = Theme.Light;
    }

    return theme;
};

const initState: ThemeState = {
    theme: getInitTheme(),
    toggleTheme: () => {},
};

const ThemeContext: Context<ThemeState> = createContext(initState);

export const ThemeStateProvider: FC<{ children: ReactNode }> = (props) => {
    const [theme, setTheme] = useState(initState.theme);

    const toggleTheme = useCallback(() => {
        setTheme(() => Theme.Light);
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    return (
        <ThemeProvider theme={themes[theme]}>
            <ThemeContext.Provider
                value={{
                    theme,
                    toggleTheme,
                }}
                {...props}
            />
        </ThemeProvider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);
