import React, { Context, createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import { Lang } from '../enums/lang.enum';

interface LangState {
    lang: Lang;
    changeLang: (lang: Lang) => void;
}

const initState: LangState = {
    lang: Lang.Ru,
    changeLang: () => {},
};

const LangContext: Context<LangState> = createContext(initState);

export const LangStateProvider: FC<{ children: ReactNode }> = (props) => {
    const [currentLang, setCurrentLang] = useState(initState.lang);

    const changeLang = useCallback(
        (lang: Lang) => {
            if (lang !== currentLang) {
                setCurrentLang(lang);
            }
        },
        [currentLang]
    );

    return (
        <LangContext.Provider
            value={{
                changeLang,
                lang: currentLang,
            }}
            {...props}
        />
    );
};

export const useLangContext = () => useContext(LangContext);
