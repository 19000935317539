import { IApiService, IActionApi, Participant, HttpResponse } from 'types';
import { generateFormData } from 'kl-b2c-ui-kit';

export class ActionsApi implements IActionApi {
    constructor(private readonly apiService: IApiService) {}

    public register(data: Participant): Promise<HttpResponse<void>> {
        return this.apiService.request<void>({
            method: 'POST',
            url: '/participant/add',
            data,
        });
    }

    public sponsor(data: Participant): Promise<HttpResponse<void>> {
        return this.apiService.request<void>({
            method: 'POST',
            url: '/sponsor/add',
            data,
        });
    }

    public papers(data: Participant): Promise<HttpResponse<void>> {
        const fd = generateFormData(data);
        Object.entries(data.agreement).forEach(([key, value]) => {
            fd.append(`agreement.${key}`, JSON.stringify(value));
        });

        return this.apiService.request<void>({
            method: 'POST',
            url: '/speaker/add',
            data: fd,
        });
    }
}
