import styled from 'styled-components';
import { mediaLg, mediaMd, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';

export const BlockTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 75px 0;

    @media (${mediaSm}) {
        margin: 0 0 55px 0;
    }
`;

export const CommitteeContainer = styled.div`
    margin: 0 0 160px 0;

    @media (${mediaLg}) {
        margin: 0 0 120px 0;
    }

    @media (${mediaMd}) {
        margin: 0 0 100px 0;
    }
`;

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;

    @media (${mediaMd}) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (${mediaSm}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (${mediaXs}) {
        grid-template-columns: 1fr;
    }
`;
