import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, withTheme } from 'styled-components';
import { BlockTitleContainer, CommitteeContainer, ContentContainer } from './styled';
import GradientTitle from 'components/gradient-title/GradientTitle';
import FlipCard from 'components/flip-card/FlipCard';
import { Committee as CommitteeModel, UserBio } from 'types';
import { UserBioFront } from 'components/user-bio/UserBioFront';
import { UserBioBack } from 'components/user-bio/UserBioBack';
import { getApiService, getStorageService } from 'services';
import { getCommitteeApiService } from 'services/api.factory';

const Committee: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const { t } = useTranslation('pages/home');
    const [activeCommittee, setActiveCommittee] = useState<number>();
    const [committees, setCommittees] = useState<CommitteeModel[] | null>(null);
    const storageService = getStorageService();
    const apiService = getApiService(storageService);
    const committeeApiService = getCommitteeApiService(apiService);

    useEffect(() => {
        committeeApiService.getAll().then((response) => setCommittees(response.data.items));
    }, []);

    if (!committees?.length) return null;

    return (
        <CommitteeContainer>
            <BlockTitleContainer>
                <GradientTitle
                    variant="h1"
                    label={t('committee-title')}
                    startColor="#29C2CC"
                    finishColor="#4AFFE9"
                    startLabelColor={theme.palette.black}
                    finishLabelColor={theme.palette.white}
                />
            </BlockTitleContainer>
            <ContentContainer>
                {committees.map(({ file, firstName, lastName, position, description, twitter }, index) => {
                    const bio: UserBio = {
                        name: `${firstName} ${lastName}`,
                        description: description || '',
                        image: file?.fileLink || '',
                        position,
                        twitter,
                    };

                    return (
                        <FlipCard
                            key={index}
                            onClick={() => {
                                if (!twitter && !description) {
                                    return;
                                }

                                if (activeCommittee === index) {
                                    setActiveCommittee(undefined);
                                } else {
                                    setActiveCommittee(index);
                                }
                            }}
                            preventFlip={!!twitter || !!description}
                            isActive={index === activeCommittee}
                            front={<UserBioFront {...bio} />}
                            back={<UserBioBack {...bio} />}
                        />
                    );
                })}
            </ContentContainer>
        </CommitteeContainer>
    );
};

export default withTheme(Committee);
