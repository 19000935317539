import { urlEncodeUtil } from 'helpers/url-encode';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import { HttpResponse, IApiService, ISpeakersApi, Speaker, SpeakerTag } from 'types';

export class SpeakersApi implements ISpeakersApi {
    constructor(private readonly apiService: IApiService) {}

    public getAll(params: PaginationInput & { tagIds: string[] }): Promise<HttpResponse<PaginationOutput<Speaker>>> {
        return this.apiService.request<PaginationOutput<Speaker>>({
            method: 'GET',
            url: `/speakerContent/get`,
            params,
            paramsSerializer: urlEncodeUtil,
        });
    }

    public getTags({ page, size }: PaginationInput): Promise<HttpResponse<PaginationOutput<SpeakerTag>>> {
        return this.apiService.request<PaginationOutput<SpeakerTag>>({
            method: 'GET',
            url: `/speakerContentTag/get?page=${page}&size=${size}&withSpeakers=true`,
        });
    }
}
