import styled, { keyframes } from 'styled-components';
import { mediaLg, mediaMd, mediaSm, mediaXs } from '../../theme/theme-helper-selectors';
import { Typography } from 'kl-b2c-ui-kit';
import { PrimaryButton } from 'components/button/styled';

const opacityAnimation = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

const screenColor = 'rgb(0, 236, 201)';
const purpureColor = 'rgb(147, 173, 255)';

export const CenterBlock = styled.div`
    margin: 0px 0px 160px 0px;
    font-family: 'Peseca';
    position: relative;
    display: flex;
    flex-direction: column;
    @media (${mediaLg}) {
        margin: 0px 0px 100px 0px;
    }
`;

export const Ellipse = styled.div`
    position: absolute;
    transform: translate(-50%, -50%);
    width: 540px;
    height: 214px;
    left: 270px;
    top: 120px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: #00fffd;
    opacity: 0.3;
    background: color(0.4261 0.9869 0.9869);
    filter: blur(150px);
    z-index: -1;
`;

export const Top = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
`;

export const Plus = styled.div`
    width: 23px;
    height: 23px;
    background-image: url(/assets/computer/plus.png);
    background-size: auto;
    background-position-y: center;
    background-position-x: center;
    margin: 1px;
    background-repeat: no-repeat;
    flex-shrink: 0;
`;
export const Minus = styled.div<{ width?: number }>`
    width: ${({ width = 10000 }) => width}px;
    height: 20px;
    background-image: url(/assets/computer/minus.png);
    background-size: auto;
    background-position-y: center;
    padding-left: 5px;
    background-repeat: {10, 0};
    background-repeat: repeat-x;
    &.all {
        flex: 1;
    }
`;

export const Vertical = styled.div`
    width: 5px;
    height: 100%;
    background-image: url(/assets/computer/vertical.png);
    background-size: auto;
    background-position-y: top;
    padding-left: 5px;
    background-repeat: repeat-y;
`;

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    font-family: 'Peseca';
    font-size: 58px;
    font-weight: 400;
    padding: 0px 25px;
    @media (${mediaLg}) {
        font-size: 46px;
    }
    @media (${mediaMd}) {
        font-size: 32px;
    }
    @media (${mediaSm}) {
        font-size: 28px;
        padding: 0px 18px;
    }
    @media (${mediaXs}) {
        font-size: 28px;
    }
`;

export const Left = styled.div`
    position: absolute;
    left: 10px;
    top: 35px;
    height: calc(100% - 70px);
`;

export const Right = styled.div`
    position: absolute;
    right: 10px;
    top: 35px;
    height: calc(100% - 70px);
`;

export const Content = styled.div`
    padding: 20px 50px 50px 70px;
    display: flex;
    flex-direction: row;

    @media (${mediaLg}) {
        padding: 15px 40px 60px 50px;
    }

    @media (${mediaMd}) {
        padding: 15px 40px 50px 50px;
        align-items: center;
    }

    @media (${mediaSm}) {
        padding: 10px 35px 10px 35px;
    }

    @media (${mediaXs}) {
        padding: 30px 35px 0 35px;
        flex-direction: column;
    }
`;

export const FirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const SecondColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 700px;
    align-items: flex-end;
`;

export const ButtonBlock = styled.div`
    padding: 0px 25px;
    position: absolute;
    right: 10%;

    @media (${mediaMd}) {
        right: 6%;
    }

    @media (${mediaSm}) {
        right: 10%;
    }

    @media (${mediaXs}) {
        right: 50%;
        transform: translateX(50%);
    }
`;

export const ComputerButton = styled(PrimaryButton)`
    border-radius: 0px;
    padding: 26px 31px;
    width: 330px;
    height: 96px;

    &:hover {
        opacity: 1;
    }

    @media (${mediaMd}) {
        width: 237px;
        height: 66px;
    }

    @media (${mediaSm}) {
        width: 166px;
        height: 53px;
    }

    span {
        font-family: 'Peseca';
        font-size: 54px;
        font-weight: 400;

        @media (${mediaMd}) {
            font-size: 40px;
        }

        @media (${mediaSm}) {
            font-size: 28px;
        }
    }
`;

export const SubTitle = styled(Typography).attrs({
    variant: 'h2',
})`
    font-family: 'Peseca';
    font-size: 103px;
    font-weight: 400;
    line-height: 1;
    color: ${screenColor};
    @media (${mediaLg}) {
        font-size: 76px;
    }
    @media (${mediaMd}) {
        font-size: 53px;
    }
    @media (${mediaSm}) {
        font-size: 53px;
    }
    @media (${mediaXs}) {
        font-size: 28px;
    }
`;

export const SubTitleBlock = styled.div`
    padding-bottom: 50px;
`;

export const Description = styled(Typography).attrs({
    variant: 'p1',
})`
    font-family: 'Peseca';
    font-size: 32px;
    font-weight: 300;
    line-height: 1.1;
    a {
        color: ${purpureColor};
        font-family: 'Peseca';
    }
    span {
        font-family: 'Peseca';
        &.highlight {
            background-color: ${purpureColor};
            padding: 0px 5px;
            color: ${({ theme }) => theme.palette.black};
        }
    }
    @media (${mediaLg}) {
        font-size: 24px;
    }
    @media (${mediaMd}) {
        font-size: 16px;
    }
    @media (${mediaSm}) {
        font-size: 16px;
    }
    @media (${mediaXs}) {
        font-size: 16px;
    }
`;

export const DescriptionBlock2 = styled.div`
    padding-bottom: 48px;
    max-width: 850px;
`;

export const DescriptionBlock3 = styled.div`
    @media (${mediaSm}) {
        padding: 0 0 35px 0;
    }

    @media (${mediaXs}) {
        padding: 0;
    }
`;

export const ScreenBg = styled.div`
    position: relative;
    width: 700px;
    margin-top: -50px;
    img {
        width: 100%;
    }
    @media (${mediaLg}) {
        width: 551px;
    }
    @media (${mediaMd}) {
        width: 356px;
    }
    @media (${mediaSm}) {
        width: 322px;
    }
    @media (${mediaXs}) {
        width: 100%;
        margin-top: 0px;
        z-index: -1;
    }
`;

export const ScreenContent = styled.div`
    position: absolute;
    left: 36%;
    top: 19%;
    display: flex;
    position: absolute;
    transform: scale(0.4);
    transform-origin: top left;
`;

export const ScreenBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    transform: translateZ(100vmax) perspective(130vmax) rotateX(17.7deg) rotateY(-22.2deg) rotateZ(4.2deg);
`;

export const ScreenTextBlock = styled.div`
    animation: ${opacityAnimation} 3s alternate-reverse infinite;
    width: 470px;
    height: 433px;
    pre {
        font-family: 'Peseca';
        color: ${screenColor};
        font-size: 14px;
        line-height: 1;
        margin: 0px;
    }
`;

export const SwitcherBlock = styled.div`
    width: 93px;
    height: 63px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const Switcher = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
`;
