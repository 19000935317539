import styled from 'styled-components';
import { Typography } from 'kl-b2c-ui-kit';
import { mediaLg, mediaMd, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';
import { gradientTextMixin } from 'styles/gradient-text-mixin';

export const DescriptionRoot = styled.div`
    margin: 0 0 160px 0;
    display: grid;
    gap: 24px;
    grid-template-columns: 66% calc(34% - 24px);
    grid-template-rows: auto;

    @media (${mediaLg}) {
        margin: 0 0 120px 0;
    }
    @media (${mediaMd}) {
        margin: 0 0 100px 0;
    }

    @media (${mediaSm}) {
        grid-template-columns: 1fr;
    }
`;

export const CardBlock = styled.div`
    padding-top: 38px;

    @media (${mediaMd}) {
        padding-top: 23px;
    }
    @media (${mediaSm}) {
        padding-top: 41px;
    }
`;

export const CardTextP1 = styled(Typography).attrs({
    variant: 'p1',
})<{ lineHeight?: number }>`
    font-size: 32px;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.8);

    .highlight {
        color: ${({ theme }) => theme.palette.white};
        font-weight: 500;
    }

    @media (${mediaLg}) {
        font-size: 24px;
        line-height: 27px;
    }
    @media (${mediaMd}) {
        font-size: 16px;
        line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 20)}px;
    }
`;

export const CardTextP2 = styled(Typography).attrs({
    variant: 'p2',
})`
    font-size: 24px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.8);

    .highlight {
        color: ${({ theme }) => theme.palette.white};
    }

    a {
        ${gradientTextMixin}
    }

    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 21px;
    }
    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 16px;
    }
`;

export const BottomMessage = styled(Typography).attrs({
    variant: 'h4',
})`
    font-size: 48px;
    line-height: 48px;
    padding-top: 64px;

    @media (${mediaMd}) {
        font-size: 32px;
        line-height: 32px;
    }
    @media (${mediaSm}) {
        font-size: 24px;
        line-height: 24px;
        padding-top: 24px;
    }
`;

export const CardTitle = styled(Typography)`
    ${gradientTextMixin}
    font-size: 72px;
    line-height: 72px;

    @media (${mediaLg}) {
        font-size: 54px;
        line-height: 54px;
    }

    @media (${mediaMd}) {
        font-size: 42px;
        line-height: 42px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const Dragon = styled.img`
    margin-top: -120px;
    width: 359px;

    @media (${mediaMd}) {
        width: 210px;
        margin-top: -75px;
    }

    @media (${mediaSm}) {
        margin-top: initial;
        position: absolute;
        right: 10px;
        top: -50px;
        width: 179px;
    }

    @media (${mediaXs}) {
        top: -30px;
        width: 128px;
    }
`;

export const SubHeader = styled(Typography).attrs({
    variant: 'h2',
})`
    ${gradientTextMixin}

    @media (${mediaLg}) {
        font-size: 35px;
        line-height: 35px;
    }
    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 24px;
    }
    @media (${mediaSm}) {
        padding-top: 0;
    }
`;

export const Date = styled.p`
    font-size: 32px;
    line-height: 36px;

    @media (${mediaLg}) {
        font-size: 24px;
        line-height: 27px;
    }

    @media (${mediaMd}) {
        font-size: 16px;
        line-height: 18px;
    }
`;

export const CardBlockWrapper = styled.div`
    @media (${mediaSm}) {
        max-width: 55%;
    }

    @media (${mediaXs}) {
        max-width: 100%;
    }
`;
