import { SasSubmit, IApiService, INotificationOrderService } from 'types';

export class NotificationOrderService implements INotificationOrderService {
    constructor(private apiService: IApiService) {}

    public addApplication = (data: SasSubmit) =>
        this.apiService
            .request<boolean>({
                method: 'POST',
                data,
                url: '/NotificationOrder/Add',
            })
            .then(() => true)
            .catch((e) => {
                console.error(e);
                return false;
            });
}
