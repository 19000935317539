import styled, { keyframes, css } from 'styled-components';
import { mediaSm, mediaXs, mediaMd, mediaBigTablet } from '../../../../theme/theme-helper-selectors';

const widthAnimation = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    margin-left: 100%;
  }
`;

export const Sure = styled.div`
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    padding: 32px 16px;
    flex-direction: column;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(120, 255, 239, 0.4) 0.39%, rgba(120, 255, 239, 0.4) 65.64%),
        linear-gradient(0deg, rgba(0, 171, 141, 0.12) 0%, rgba(0, 171, 141, 0.12) 100%), rgba(17, 26, 36, 0.72);
    background: linear-gradient(
            0deg,
            color(display-p3 0.6157 1 0.9373 / 0.4) 0.39%,
            color(display-p3 0.6157 1 0.9373 / 0) 65.64%
        ),
        linear-gradient(
            0deg,
            color(display-p3 0 0.6588 0.5569 / 0.12) 0%,
            color(display-p3 0 0.6588 0.5569 / 0.12) 100%
        ),
        color(display-p3 0.0745 0.102 0.1373 / 0.72);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    .root {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
            color: var(--Primary-White, #fff);
            color: var(--Primary-White, color(display-p3 1 1 1));
            font-feature-settings: 'liga' off;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            padding-bottom: 24px;
            display: flex;
            align-items: center;
            width: 100%;
            img {
                padding-right: 8px;
            }
        }
        button {
            padding: 16px 20px 16px 20px;
            font-size: 16px;
            border-radius: 8px;
            background-color: rgba(61, 232, 202, 1);
            border: none;
            width: 300px;
            color: rgba(29, 29, 27, 1);
            font-size: 16px;
            font-weight: 600;
        }
    }
`;

export const RootComingSoon = styled.div`
    background: #101219;
    background: color(display-p3 0.0627 0.0706 0.0941);
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
`;

export const Share = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 840px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: #00fffd;
    opacity: 0.7;
    background: color(display-p3 0.4261 0.9869 0.9869);
    filter: blur(150px);
    @media (${mediaSm}) {
        width: 90%;
        filter: blur(100px);
        opacity: 0.5;
        margin-top: 70px;
    }
    @media (${mediaXs}) {
        width: 90%;
        filter: blur(100px);
        margin-top: 0px;
    }
`;

export const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
    position: absolute;
    @media (${mediaSm}) {
        margin-top: 70px;
    }
    @media (${mediaXs}) {
        margin-top: -60px;
    }
`;
export const LoadingProcess = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    max-width: 530px;
    width: 95%;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 171, 141, 0.12) 0%, rgba(0, 171, 141, 0.12) 100%), rgba(17, 26, 36, 0.64);

    background: linear-gradient(
            0deg,
            color(display-p3 0 0.6588 0.5569 / 0.12) 0%,
            color(display-p3 0 0.6588 0.5569 / 0.12) 100%
        ),
        color(display-p3 0.0745 0.102 0.1373 / 0.64);

    backdrop-filter: blur(5px);
    padding: 20px 24px;
    p {
        color: rgba(255, 255, 255, 1);
        color: color(display-p3 1 1 1 / 1);
        font-size: 18px;
        font-weight: 600;
        display: flex;
        span {
            flex: 1;
            font-weight: 400;
        }
    }
`;

export const PercentProgress = styled.div<{ width: number }>`
    margin-top: 5px;
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    background: rgba(41, 204, 177, 1);
    .color {
        transition: width 1s ease-in-out;
        width: ${(props) => props.width}%;
        position: relative;
        height: 4px;
        background: var(
            --Gradients-Gradient-1,
            radial-gradient(56.79% 56.79% at 31.79% -10%, rgba(172, 190, 255, 0.9) 0%, #acbeff 100%),
            radial-gradient(109.66% 109.66% at 92.5% 1.43%, #d7f1ff 0%, #d7f1ff 79.69%),
            radial-gradient(54.64% 70.12% at 3.93% 92.86%, #66d8f6 0%, #68d7f7 77.23%),
            linear-gradient(130deg, #29ffe9 27.55%, #bac1ff 70.53%)
        );

        background: var(
            --Gradients-Gradient-1,
            radial-gradient(
                56.79% 56.79% at 31.79% -10%,
                color(display-p3 0.6863 0.7412 0.9961 / 0.9) 0%,
                color(display-p3 0.6863 0.7412 0.9961 / 0) 100%
            ),
            radial-gradient(
                109.66% 109.66% at 92.5% 1.43%,
                color(display-p3 0.8625 0.9424 1) 0%,
                color(display-p3 0.8625 0.9424 1 / 0) 79.69%
            ),
            radial-gradient(
                54.64% 70.12% at 3.93% 92.86%,
                color(display-p3 0.5176 0.8353 0.949) 0%,
                color(display-p3 0.5216 0.8314 0.9529 / 0) 77.23%
            ),
            linear-gradient(
                130deg,
                color(display-p3 0.4863 1 0.9137) 27.55%,
                color(display-p3 0.7333 0.7569 0.9961) 70.53%
            )
        );
    }
`;

export const InfinityProgress = styled.div`
    margin-top: 5px;
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    background: rgba(41, 204, 177, 1);
    .color {
        animation: ${widthAnimation} 3s linear infinite;
        position: relative;
        height: 4px;
        background: var(
            --Gradients-Gradient-1,
            radial-gradient(56.79% 56.79% at 31.79% -10%, rgba(172, 190, 255, 0.9) 0%, #acbeff 100%),
            radial-gradient(109.66% 109.66% at 92.5% 1.43%, #d7f1ff 0%, #d7f1ff 79.69%),
            radial-gradient(54.64% 70.12% at 3.93% 92.86%, #66d8f6 0%, #68d7f7 77.23%),
            linear-gradient(130deg, #29ffe9 27.55%, #bac1ff 70.53%)
        );

        background: var(
            --Gradients-Gradient-1,
            radial-gradient(
                56.79% 56.79% at 31.79% -10%,
                color(display-p3 0.6863 0.7412 0.9961 / 0.9) 0%,
                color(display-p3 0.6863 0.7412 0.9961 / 0) 100%
            ),
            radial-gradient(
                109.66% 109.66% at 92.5% 1.43%,
                color(display-p3 0.8625 0.9424 1) 0%,
                color(display-p3 0.8625 0.9424 1 / 0) 79.69%
            ),
            radial-gradient(
                54.64% 70.12% at 3.93% 92.86%,
                color(display-p3 0.5176 0.8353 0.949) 0%,
                color(display-p3 0.5216 0.8314 0.9529 / 0) 77.23%
            ),
            linear-gradient(
                130deg,
                color(display-p3 0.4863 1 0.9137) 27.55%,
                color(display-p3 0.7333 0.7569 0.9961) 70.53%
            )
        );
    }
`;

export const LeftTop = styled.div`
    position: absolute;
    left: 0;
    top: 38px;
    max-width: 515px;
    padding-left: 44px;
    @media (${mediaMd}) {
        top: 20px;
        max-width: 800px;
    }
    @media (${mediaSm}) {
        padding: 0 15px;
    }
    img {
        padding-bottom: 20px;
        @media (${mediaSm}) {
            padding-bottom: 30px;
        }

        @media (${mediaXs}) {
            padding-bottom: 10px;
            width: 120px;
        }
    }
    p {
        color: var(--white-100, #fff);

        color: var(--white-100, color(display-p3 1 1 1));
        font-feature-settings: 'liga' off;
        text-shadow: 0 4px 24px rgba(12, 51, 70, 0.4);
        text-shadow: 0 4px 24px color(display-p3 0.092 0.1951 0.2688 / 0.4);
        font-size: 81px;
        font-style: normal;
        font-weight: 500;
        line-height: 81px;
        padding-bottom: 60px;
        @media (${mediaMd}) {
            font-size: 72px;
            line-height: 72px;
            padding-left: 0;
            padding-bottom: 32px;
            padding-right: 80px;
        }
        @media (${mediaXs}) {
            font-size: 35px;
            line-height: 35px;
            padding-bottom: 16px;
        }
    }
    span {
        color: var(--Primary-Kaspersky-green-200, color(display-p3 0.2392 0.9098 0.7922));
    }
`;
export const Center = styled.div`
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    img {
        height: 64px;
    }
    @media (${mediaMd}) {
        left: auto;
        right: 44px;
        transform: none;
    }
    @media (${mediaSm}) {
        right: 15px;
        img {
            height: 34px;
        }
    }
`;
export const RigthTopDiv = styled.div`
    &.absolute {
        @media (${mediaMd}) {
            display: none;
        }
    }
    &:not(.absolute) {
        position: static;
        display: flex;
        flex-direction: column;
        max-width: 355px;
        & > * {
            padding: 0;
        }
        @media (${mediaBigTablet}) {
            display: none;
        }
    }
    position: absolute;
    right: 0;
    padding-right: 44px;
    top: 38px;
    @media (${mediaMd}) {
        right: auto;
        left: 40px;
        top: 290px;
    }
    @media (${mediaXs}) {
        top: 140px;
    }
    @media (max-width: 680px) {
        top: 170px;
    }
    @media (max-width: 360px) {
        top: 210px;
    }
    .save_date {
        color: #fff;
        color: color(display-p3 1 1 1);
        font-feature-settings: 'liga' off;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        @media (${mediaMd}) {
            font-size: 18px;
            line-height: 22px;
        }
        @media (${mediaSm}) {
            font-size: 17px;
            line-height: 19px;
        }
        @media (${mediaXs}) {
            font-size: 12px;
            line-height: 12px;
        }
    }
    .date {
        color: var(--Primary-Kaspersky-green-200, #00ecc9);
        color: var(--Primary-Kaspersky-green-200, color(display-p3 0.2392 0.9098 0.7922));
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'liga' off;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px;
        @media (${mediaMd}) {
            font-size: 32px;
            line-height: 32px;
        }
        @media (${mediaXs}) {
            font-size: 20px;
            line-height: 21px;
        }
    }
`;

export const LeftBottom = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 44px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (${mediaSm}) {
        padding-left: 15px;
    }
    @media (max-width: 671px) {
        bottom: 30px;
    }
    p {
        color: var(--Primary-White, #fff);
        color: var(--Primary-White, color(display-p3 1 1 1));
        font-feature-settings: 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        padding-right: 16px;
        @media (max-width: 800px) {
            padding-bottom: 8px;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        a {
            padding-right: 16px;
        }
    }
    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (${mediaMd}) {
        position: absolute;
        bottom: 190px;
        left: 44px;
    }

    @media (${mediaSm}) {
        position: absolute;
        bottom: 195px;
        left: 15px;
    }
`;

export const RightBottom = styled.div`
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding-right: 44px;
    padding-bottom: 20px;
    a {
        color: #8caeff;

        color: color(display-p3 0.5765 0.6784 1);
        font-feature-settings: 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        text-decoration-line: underline;
        padding-left: 40px;
        cursor: pointer;
        @media (max-width: 671px) {
            padding-left: 0px;
            font-size: 16px;
            line-height: 18px;
            padding-right: 32px;
        }
        &:hover {
            color: #fff;
        }
    }
    @media (max-width: 671px) {
        right: auto;
        padding-right: 0px;
        padding-left: 15px;
    }
`;

export const LeftButtonDiv = styled.div`
    &.absolute {
        @media (${mediaBigTablet}) {
            display: none;
        }
    }
  
    &:not(.absolute) {
        @media (${mediaMd}) {
          display: none;
        }
    }
  
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    padding: 16px 24px;
    justify-content: flex-start;
    gap: 16px;
    border-radius: 8px;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(0, 171, 141, 0.12) 0%, rgba(0, 171, 141, 0.12) 100%),
        rgba(255, 255, 255, 0.16);

    background: linear-gradient(
            0deg,
            color(display-p3 0 0.6588 0.5569 / 0.12) 0%,
            color(display-p3 0 0.6588 0.5569 / 0.12) 100%
        ),
        color(display-p3 1 1 1 / 0.16);
    width: min-content;
    height: 60px;
    backdrop-filter: blur(15px);

    @media (${mediaMd}) {
        padding: 12px 16px;
        height: 48px;
    }
  
    .hidden_text {
        color: rgba(255, 255, 255, 0.8);
        color: color(display-p3 1 1 1 / 0.8);
        font-feature-settings: 'liga' off;
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        padding-bottom: 0px;
        padding-left: 0px;
        @media (${mediaMd}) {
            font-size: 14px;
            line-height: 16px;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            width: 26px;
            height: 26px;
            padding: 0px;
            @media (${mediaSm}) {
                width: 24px;
                height: 24px;
            }
        }
        p {
            color: var(--Primary-White, #fff);
            color: var(--Primary-White, color(display-p3 1 1 1));
            
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            padding-left: 16px;
            padding-right: 8px;
            padding-bottom: 0px;
            flex: 1;
            white-space: nowrap;
            @media (${mediaMd}) {
                font-size: 18px;
                line-height: 21px;
                padding-left: 10px;
                padding-right: 4px;
            }
        }
    }
    .arrow_go {
        transition: transform 300ms ease;
    }
    @media (${mediaSm}) {
        padding: 12px 16px;
        height: 50px;
    }
    &:hover {
        background: linear-gradient(0deg, rgba(0, 171, 141, 0.24), rgba(0, 171, 141, 0.24));
        linear-gradient(0deg, color(display-p3 0.000 0.659 0.557 / 0.24), color(display-p3 0.000 0.659 0.557 / 0.24)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
        linear-gradient(0deg, color(display-p3 1.000 1.000 1.000 / 0.16), color(display-p3 1.000 1.000 1.000 / 0.16));
        
    }
    ${(props) =>
        props.className?.includes('active') &&
        css`
             {
                height: 290px;
                bottom: 100px;
                max-width: 360px;
                margin-right: 15px;
                width: auto;
                &.secondary {
                    height: 330px;

                    @media (${mediaMd}) {
                        height: 245px;
                    }
                }
                .arrow_go {
                    transform: rotateZ(180deg);
                }
                @media (${mediaMd}) {
                    height: 200px;
                    bottom: 264px;
                }
                @media (max-width: 365px) {
                    height: 205px;
                }
                @media (max-width: 336px) {
                    height: 220px;
                }
            }
        `}
`;

export const RightButtonDiv = styled.div`
    position: absolute;
    bottom: 80px;
    right: -20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    justify-content: flex-start;
    gap: 16px;
    border-radius: 8px;
    overflow: hidden;
    transition: height 300ms ease;
    background: linear-gradient(0deg, rgba(0, 171, 141, 0.12) 0%, rgba(0, 171, 141, 0.12) 100%),
        rgba(255, 255, 255, 0.16);

    background: linear-gradient(
            0deg,
            color(display-p3 0 0.6588 0.5569 / 0.12) 0%,
            color(display-p3 0 0.6588 0.5569 / 0.12) 100%
        ),
        color(display-p3 1 1 1 / 0.16);

    backdrop-filter: blur(15px);

    @media (${mediaMd}) {
        right: auto;
        left: 45px;
        bottom: 100px;
    }
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .images {
            width: 48px;
            height: 48px;
            background-color: #00a88e;
            border-radius: 24px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (${mediaSm}) {
                width: 32px;
                height: 32px;
            }
        }
        img {
            width: 24px;
            height: 24px;
        }
        p {
            padding-left: 16px;
            padding-right: 50px;
            color: var(--Primary-White, #fff);
            color: var(--Primary-White, color(display-p3 1 1 1));
            
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            span {
                color: var(--Primary-Kaspersky-green-200, color(display-p3 0.2392 0.9098 0.7922));
                
                font-style: normal;
                font-weight: 500;
            }
            @media (${mediaMd}) {
                font-size: 18px;
                line-height: 21px;
                padding-left: 10px;
                padding-right: 4px;
            }
        }
    }
    @media (${mediaSm}) {
        left: 15px;
        right: auto;
        padding: 12px 16px;
        bottom: 120px;
    }
    &:hover {
        background: linear-gradient(0deg, rgba(0, 171, 141, 0.24), rgba(0, 171, 141, 0.24));
        linear-gradient(0deg, color(display-p3 0.000 0.659 0.557 / 0.24), color(display-p3 0.000 0.659 0.557 / 0.24)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
        linear-gradient(0deg, color(display-p3 1.000 1.000 1.000 / 0.16), color(display-p3 1.000 1.000 1.000 / 0.16));
        
    }
`;

export const IconContainer = styled.div`
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Answer = styled.div`
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 380px;
    width: 95%;
    display: inline-flex;
    padding: 32px;
    flex-direction: column;
    border-radius: 16px;

    @media (${mediaXs}) {
        padding: 20px;
    }
    background: linear-gradient(0deg, rgba(120, 255, 239, 0.4) 0.39%, rgba(120, 255, 239, 0.4) 65.64%),
        linear-gradient(0deg, rgba(0, 171, 141, 0.12) 0%, rgba(0, 171, 141, 0.12) 100%), rgba(17, 26, 36, 0.72);

    background: linear-gradient(
            0deg,
            color(display-p3 0.6157 1 0.9373 / 0.4) 0.39%,
            color(display-p3 0.6157 1 0.9373 / 0) 65.64%
        ),
        linear-gradient(
            0deg,
            color(display-p3 0 0.6588 0.5569 / 0.12) 0%,
            color(display-p3 0 0.6588 0.5569 / 0.12) 100%
        ),
        color(display-p3 0.0745 0.102 0.1373 / 0.72);
    backdrop-filter: blur(15px);
    .root {
        position: relative;
        h4 {
            color: var(--Primary-White, #fff);
            color: var(--Primary-White, color(display-p3 1 1 1));
            font-feature-settings: 'liga' off;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            padding-bottom: 24px;
        }
    }
    .first {
        color: var(--Primary-White, #fff);
        color: var(--Primary-White, color(display-p3 1 1 1));
        font-feature-settings: 'liga' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        p {
            margin-bottom: 8px;
            &:last-child {
                margin-top: 16px;
                margin-bottom: 0;
            }
        }
        @media (${mediaXs}) {
            font-size: 16px;
            line-height: 18px;
        }
        .heading {
            color: var(--Primary-Kaspersky-green-200, #00ecc9);
            color: var(--Primary-Kaspersky-green-200, color(display-p3 0.2392 0.9098 0.7922));
            font-feature-settings: 'liga' off;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            @media (${mediaXs}) {
                font-size: 24px;
                line-height: 27px;
            }
        }
    }
    .agreements {
        padding-top: 24px;
        display: flex;
        flex-direction: row;
        a {
            font-size: 12px;
            color: ${({ theme }) => theme.palette.white};
            font-weight: 600;
            line-height: 14px;
            text-decoration-line: underline;
            &:hover {
                color: rgba(61, 232, 202, 1);
            }
        }
    }
    .fields {
        display: flex;
        flex-direction: row;
        padding-top: 24px;
        gap: 8px;
        @media (${mediaXs}) {
            padding-top: 16px;
        }
        input {
            flex: 1;
            border-radius: 8px;
            border: 1px solid #fff;
            border: 1px solid color(display-p3 1 1 1);
            display: flex;
            padding: 16px 12px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-color: transparent;

            color: color(display-p3 1 1 1 / 1);
            font-feature-settings: 'liga' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
            &::placeholder {
                color: color(display-p3 1 1 1 / 0.6);
                font-feature-settings: 'liga' off;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
            &.error {
                border-color: #ff7390 !important;
            }
            &:focus {
                border-color: rgba(41, 204, 177, 1);
            }
            &:autofill {
                transition: background-color 5000000s ease-in-out 0s;
                -webkit-text-fill-color: #fff;
            }
        }
        button {
            display: flex;
            width: 48px;
            height: 48px;
            padding: 16px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 0px;
            opacity: 0.5;
            background: var(--Primary-Kaspersky-green-200, #00ecc9);
            background: var(--Primary-Kaspersky-green-200, color(display-p3 0.2392 0.9098 0.7922));
            box-shadow: 0px 8px 32px 0px rgba(0, 213, 172, 0.4);
            box-shadow: 0px 8px 32px 0px color(display-p3 0.1373 0.8196 0.6824 / 0.4);
            img {
                width: 24px;
                height: 24px;
            }
            &:disabled {
                cursor: not-allowed;
            }
            &:hover:not([disabled]) {
                opacity: 1;
            }
        }
    }
    .grecaptcha-badge {
        display: none !important;
    }
    .error {
        color: #ff7390;
        color: color(display-p3 1 0.4902 0.5725);
        font-feature-settings: 'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        align-self: stretch;
        @media (${mediaXs}) {
            font-size: 10px;
            line-height: 12px;
            padding-top: 5px;
        }
    }
`;

export const IncorrectShare = styled.div`
    position: absolute;
    left: -159px;
    top: -93px;
    width: 426px;
    height: 258px;
    border-radius: 426px;
    opacity: 0.9;
    background: #ff7390;
    filter: blur(100px);
    will-change: filter;
    transform: translate3d(0, 0, 0);
`;
export const СorrectShare = styled.div`
    border-radius: 426px;
    background: #6a93ff;
    filter: blur(100px);
    will-change: filter;
    transform: translate3d(0, 0, 0);
    position: absolute;
    left: -159px;

    top: -93px;
    width: 426px;
    height: 258px;
`;
export const BlackScreen = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: default;
`;

export const InvisibleScreen = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    cursor: default;
`;

export const CaptchaText = styled.div`
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    color: var(--white-60, color(display-p3 1 1 1 / 0.6));
    font-feature-settings: 'liga' off;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    a {
        color: var(--white-100, color(display-p3 1 1 1));
        font-feature-settings: 'liga' off;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        text-decoration-line: underline;
        &:hover {
            color: rgba(61, 232, 202, 1);
        }
    }
    padding-top: 16px;
`;

export const SuccessEl = styled.div<{ gradient?: string; color?: string; gap?: number }>`
    overflow: hidden;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 380px;
    width: 95%;
    display: inline-flex;
    padding: 32px;
    flex-direction: column;
    gap: ${({ gap }) => (gap !== undefined ? gap : 24)}px;
    border-radius: 16px;
    @media (${mediaXs}) {
        padding: 20px;
    }

    background: ${({ gradient }) =>
        gradient
            ? gradient
            : 'linear-gradient(0deg, rgba(120, 255, 239, 0.4) 0.39%, rgba(120, 255, 239, 0.4) 65.64%), linear-gradient(0deg, rgba(0, 171, 141, 0.12) 0%, rgba(0, 171, 141, 0.12) 100%), rgba(17, 26, 36, 0.72)'};

    backdrop-filter: blur(15px);
    .root {
        position: relative;
        h4 {
            color: ${({ color, theme }) => (color ? color : theme.palette.text.primary)};
            font-feature-settings: 'liga' off;
            /* Subhead/S1 – 32 Subhead 1 */
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
        }
        p {
            color: ${({ color, theme }) => (color ? color : theme.palette.text.primary)};
            font-feature-settings: 'liga' off;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            padding-top: 24px;
            @media (${mediaXs}) {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
`;
