import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from '../../theme/theme-helper-selectors';

export const Card = styled.div`
    position: relative;
    padding: 48px;
    min-width: 50px;
    min-height: 50px;
    background: linear-gradient(180deg, #272727 0%, #1e1e1e 100%);
    border-radius: 32px;

    @media (${mediaMd}) {
        padding: 32px;
        border-radius: 32px;
    }

    @media (${mediaSm}) {
        padding: 24px;
        border-radius: 24px;
    }

    @media (${mediaXs}) {
        padding: 16px;
        border-radius: 16px;
    }

    &::before {
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 32px;
        content: '';
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(153, 153, 153, 0.1) 100%);
        z-index: -1;
        position: absolute;

        @media (${mediaSm}) {
            border-radius: 24px;
        }

        @media (${mediaXs}) {
            border-radius: 16px;
        }
    }
`;
