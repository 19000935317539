import { RegisterOptions } from 'react-hook-form';
import { ACCEPTED_FILE_FORMATS, EMAIL_PATTERN, TEN_MB_IN_BYTES } from 'consts/formats';
import { Participant } from 'types';
import { fileValidator } from '../../../../helpers/file-validator';

export const generateRules = (
    controllerKey: keyof Omit<Participant & { description: string }, 'captchaCode' | 'agreement'>,
    t: (key: string, options?: Record<string, number | string>) => string
): RegisterOptions => {
    const rules: Record<
        keyof Omit<Participant & { description: string }, 'captchaCode' | 'agreement'>,
        RegisterOptions
    > = {
        email: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 254,
                message: t('validations:fieldIsTooLong', {
                    length: 254,
                }),
            },
            pattern: {
                value: EMAIL_PATTERN,
                message: t('validations:emailHasNotCorrectFormat'),
            },
        },
        firstName: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 200,
                message: t('validations:fieldIsTooLong', {
                    length: 200,
                }),
            },
        },
        lastName: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 200,
                message: t('validations:fieldIsTooLong', {
                    length: 200,
                }),
            },
        },
        position: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 200,
                message: t('validations:fieldIsTooLong', {
                    length: 200,
                }),
            },
        },
        company: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 100,
                message: t('validations:fieldIsTooLong', {
                    length: 100,
                }),
            },
        },
        description: {
            maxLength: {
                value: 1500,
                message: t('validations:fieldIsTooLong', {
                    length: 1500,
                }),
            },
        },
        comments: {
            maxLength: {
                value: 255,
                message: t('validations:fieldIsTooLong', {
                    length: 255,
                }),
            },
        },
        phone: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 20,
                message: t('validations:fieldIsTooLong', {
                    length: 20,
                }),
            },
            validate: (value: string) => {
                const valid = /^\d{7,}$/.test(value.replace(/[\s()+\-.]|ext/gi, ''));

                if (!valid) {
                    return t('validations:notAPhone');
                }
            },
        },
        topic: {
            required: {
                value: true,
                message: t('validations:fieldIsRequired'),
            },
            maxLength: {
                value: 200,
                message: t('validations:fieldIsTooLong', {
                    length: 200,
                }),
            },
        },
        file: {
            validate: (file: File) => fileValidator(file, TEN_MB_IN_BYTES, ACCEPTED_FILE_FORMATS, t),
        },
    };

    return rules[controllerKey];
};
