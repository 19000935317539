import { Typography } from 'kl-b2c-ui-kit';
import { mediaLg, mediaMd, mediaXs } from '../../theme/theme-helper-selectors';
import styled from 'styled-components';

export const Block = styled.div`
    position: relative;
`;

export const GradientBackgroundBlock = styled.div<{ start: string; finish: string; width: number }>`
    background: linear-gradient(90deg, ${({ start }) => start}, ${({ finish }) => finish});
    width: calc(${({ width }) => width}%);
    max-width: 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    display: flex;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: -1;
`;

export const GradientTitleBlock = styled(Typography)<{ width: number; start: string; finish: string }>`
    background: linear-gradient(
        to right,
        ${({ start }) => start} ${({ width }) => width}%,
        ${({ finish }) => finish} ${({ width }) => width}%
    );
    font-weight: 500;
    font-size: 72px;
    line-height: 72px;
    padding: 8px 24px;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (${mediaLg}) {
        font-size: 54px;
        line-height: 54px;
    }

    @media (${mediaMd}) {
        font-size: 42px;
        line-height: 42px;
    }

    @media (${mediaXs}) {
        font-size: 24px;
        line-height: 27px;
        padding: 8px 12px;
    }
`;
