import React, { FC } from 'react';
import styled from 'styled-components';
import { mediaSm, mediaXs } from '../../theme/theme-helper-selectors';

type Props = {
    children?: React.ReactNode;
    noHeader?: boolean;
};

const StyledMain = styled.main<Pick<Props, 'noHeader'>>`
    position: relative;
    background-attachment: fixed;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 64px;

    @media (${mediaSm}) {
        padding-top: 32px;
    }

    @media (${mediaXs}) {
        padding-top: 16px;
    }
`;

const Main: FC<Props> = ({ children }) => <StyledMain>{children}</StyledMain>;

export default Main;
