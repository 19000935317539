import styled from 'styled-components';
import { mediaLg, mediaMd, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';
import { Typography } from 'kl-b2c-ui-kit';
import { gradientTextMixin } from 'styles/gradient-text-mixin';
import { PrimaryLink } from 'components/button/styled';
import { Card } from 'components/card/styled';

export const BlockTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 160px 0;
    @media (${mediaLg}) {
        margin: 0 0 120px 0;
    }
    @media (${mediaMd}) {
        margin: 0 0 100px 0;
    }
    @media (${mediaXs}) {
        margin: 0 0 40px 0;
    }
`;

export const OpportunitiesItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (${mediaSm}) {
        flex-wrap: wrap;
    }
`;

export const CardImage = styled.img`
    margin-bottom: 25px;
    margin-top: -180px;
    width: 359px;

    @media (${mediaLg}) {
        width: 298px;
        margin-bottom: 19px;
        margin-top: -145px;
    }

    @media (${mediaMd}) {
        width: 208px;
        margin-bottom: 13px;
        margin-top: -120px;
    }

    @media (${mediaSm}) {
        margin-bottom: 25px;
        margin-top: 0;
        position: absolute;
        top: -55px;
        left: 24px;
    }

    @media (${mediaXs}) {
        position: static;
        width: 127px;
    }
`;

export const CardTitle = styled(Typography).attrs({
    variant: 'h2',
})`
    font-weight: 500;
    ${gradientTextMixin}
    margin-bottom: 38px;

    @media (${mediaLg}) {
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 28px;
    }

    @media (${mediaMd}) {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 20px;
    }

    @media (${mediaSm}) {
        margin-bottom: 24px;
    }
`;

export const CardDescription = styled(Typography).attrs({
    variant: 'p1',
})<{ margin?: number }>`
    font-size: 24px;
    line-height: 27px;
    color: ${({ theme }) => theme.palette.white};
    margin: 0 0 ${({ margin }) => (margin ? margin : 40)}px 0;

    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 21px;
    }
    @media (${mediaSm}) {
        font-size: 14px;
        line-height: 16px;
    }
`;

export const CardButton = styled(PrimaryLink)`
    font-size: 24px;
    line-height: 24px;
    padding: 25px;
    width: 100%;
    height: 74px;
    margin-top: auto;
    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 18px;
        padding: 21px;
        height: 60px;
    }
    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 16px;
        padding: 16px 0;
        height: 48px;
    }
`;

export const BlockCard = styled(Card)`
    width: 33%;
    display: flex;
    flex-direction: column;

    @media (${mediaSm}) {
        width: 100%;
        padding-left: 252px;
    }

    @media (${mediaXs}) {
        padding-left: 16px;
    }
`;

export const CardList = styled.ul`
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    margin: 0 0 40px 0;

    @media (${mediaLg}) {
        margin: 0 0 28px 0;
    }

    @media (${mediaMd}) {
        margin: 0 0 20px 0;
    }

    @media (${mediaSm}) {
        margin: 0 0 24px 0;
    }
`;

export const CardListItem = styled.li`
    margin: 0 0 18px 0;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: flex-start;
    font-size: 24px;
    line-height: 27px;
    font-weight: 500;

    @media (${mediaLg}) {
        font-size: 18px;
        line-height: 21px;
    }

    @media (${mediaMd}) {
        font-size: 14px;
        line-height: 16px;
    }
`;

export const CardListItemText = styled.div`
    & > span {
        font-weight: 300;
        opacity: 0.6;

        font-size: 21px;
        line-height: 24px;

        @media (${mediaLg}) {
            font-size: 16px;
            line-height: 18px;
        }

        @media (${mediaMd}) {
            font-size: 12px;
            line-height: 14px;
        }
    }
`;

export const Pricing = styled.div<{ noMargin?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 16px;
    font-weight: 500;
    margin: ${({ noMargin }) => (noMargin ? 0 : '0 0 25px 0')};
    align-items: flex-start;
`;

export const Discount = styled.div`
    ${gradientTextMixin}
    font-size: 42px;
    line-height: 42px;

    @media (${mediaLg}) {
        font-size: 32px;
        line-height: 36px;
    }

    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const OpportunitiesContainer = styled.div`
    margin: 0 0 160px 0;

    @media (${mediaLg}) {
        margin: 0 0 120px 0;
    }

    @media (${mediaMd}) {
        margin: 0 0 100px 0;
    }
`;
