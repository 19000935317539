import React, { PropsWithChildren, useRef } from 'react';
import { Form, Disclaimer } from './styled';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Checkbox } from 'kl-b2c-ui-kit';
import { useFormContext, Controller, FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useSettings } from 'contexts/settings.context';
import { PrimaryButton } from 'components/button/styled';
import { DEFAULT_FORM_STATE } from 'containers/pages/action-page/ActionPage';

type Policy = {
    name: string;
    label: string;
    required?: boolean;
};

type FormBaseProps = {
    type?: 'primary' | 'secondary';
    gap: string;
    policies: Policy[];
    submit: (data: FieldValues) => void;
    hideSubmitBtn?: boolean;
    loading?: boolean;
    submitLabel?: string;
};

const FormBase = (props: PropsWithChildren<FormBaseProps>) => {
    const { gap, hideSubmitBtn, policies, children, submit, loading, type = 'primary', submitLabel = 'submit' } = props;
    const captchaRef = useRef<ReCAPTCHA>(null);
    const { t } = useTranslation('translation');
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useFormContext();
    const { settings } = useSettings();

    const onSubmit = async (data: FieldValues) => {
        const captchaCode = await captchaRef?.current?.executeAsync();
        return submit({ ...data, captchaCode });
    };

    return (
        <Form gap={gap} loading={loading} onSubmit={handleSubmit(onSubmit)}>
            {children}
            {policies.map(({ name, label, required }) => (
                <Controller
                    key={name}
                    name={name}
                    control={control}
                    rules={{ required }}
                    defaultValue={DEFAULT_FORM_STATE['agreement']['privacyPolicyIsSelected']}
                    render={({ field: { onChange, value } }) => {
                        const [root, key] = name.split('.');

                        return (
                            <Checkbox
                                value={value}
                                size={'large'}
                                // @ts-ignore
                                color={type}
                                onChange={(value) => onChange(value)}
                                // @ts-ignore
                                isValid={!(errors && errors[root] && errors[root][key])}
                            >
                                <Disclaimer
                                    type={type}
                                    variant={'p6'}
                                    color={type === 'primary' ? 'primary' : 'textSecondary'}
                                    fontWeight={'regular'}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(label, { ADD_ATTR: ['target'] }),
                                        }}
                                    />
                                </Disclaimer>
                            </Checkbox>
                        );
                    }}
                />
            ))}
            <Disclaimer
                type={type}
                variant={'p6'}
                color={type === 'primary' ? 'primary' : 'textSecondary'}
                fontWeight={'regular'}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('form.captcha'), { ADD_ATTR: ['target'] }),
                    }}
                />
            </Disclaimer>

            {settings && (
                <ReCAPTCHA size={'invisible'} ref={captchaRef} badge="bottomright" sitekey={settings.captchaKey} />
            )}

            {!hideSubmitBtn && (
                <PrimaryButton style={{ marginTop: 'auto' }} type={'submit'}>
                    {submitLabel}
                </PrimaryButton>
            )}
        </Form>
    );
};

export default FormBase;
