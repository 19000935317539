import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from '../../../../../../../theme/theme-helper-selectors';
import { Card } from 'components/card/styled';

export const CardBlock = styled(Card)``;

export const EmptyEventsBlock = styled.div`
    display: flex;
    flex-direction: row;
    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const EventsExistBlock = styled.div`
    display: flex;
    flex-direction: row;
    @media (${mediaSm}) {
        flex-direction: column;
    }
`;
export const HideText = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.6);
    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 21px;
    }
    @media (${mediaXs}) {
        font-size: 14px;
        line-height: 16px;
    }
`;
export const DescriptionEventBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 24px;
`;
export const SessionDescription = styled.p`
    color: ${({ theme }) => theme.palette.white};
    font-size: 42px;
    font-weight: 500;
    line-height: 42px;
    padding-top: 10px;
    @media (${mediaMd}) {
        font-size: 32px;
        line-height: 36px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const SessionName = styled.p`
    color: ${({ theme }) => theme.palette.white};
    font-size: 42px;
    font-weight: 500;
    line-height: 42px;
    @media (${mediaMd}) {
        font-size: 32px;
        line-height: 36px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const ModeratorEventBlock = styled.div`
    display: flex;
    flex-direction: column;
    @media (${mediaSm}) {
        padding-top: 24px;
    }
`;
export const ModeratorName = styled.p`
    color: ${({ theme }) => theme.palette.white};
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    padding-top: 10px;
    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 27px;
    }
    @media (${mediaXs}) {
        font-size: 21px;
        line-height: 24px;
    }
`;

export const SessionTimeBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    @media (${mediaXs}) {
        flex-direction: row;
        padding-top: 0px;
    }
`;

export const StartTime = styled.p`
    color: ${({ theme }) => theme.palette.white};
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    padding-bottom: 8px;
    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 27px;
    }
    @media (${mediaXs}) {
        padding-bottom: 0px;
    }
`;
export const EndTime = styled.p`
    color: rgba(255, 255, 255, 0.6);
    font-size: 24px;
    font-weight: 500;
    line-height: 27px;
    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 21px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        line-height: 27px;
    }
`;
export const EventEmptyDescriptionBlock = styled.div`
    display: flex;
    flex: 1;
    padding-left: 24px;
    padding-right: 24px;
    @media (${mediaXs}) {
        padding-left: 0px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
`;

export const LocationBlock = styled.div`
    display: flex;
    flex-direction: row;
`;

export const LocationDescription = styled.p`
    font-size: 42px;
    font-weight: 500;
    line-height: 42px;
    color: rgba(255, 255, 255, 0.6);
    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 27px;
    }
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 21px;
    }
`;

export const EventContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    gap: 64px;
    @media (${mediaMd}) {
        padding-top: 32px;
    }
`;
