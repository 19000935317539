import styled, { css } from 'styled-components';
import { gradientTextMixin } from 'styles/gradient-text-mixin';
import { mediaMd, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';
import { Button } from 'kl-b2c-ui-kit';

type IntroButtonType = 'outlined' | 'contained' | 'transparent';

const containerMixin = css`
    max-width: 1200px;
    align-items: center;
    justify-content: center;
`;

export const IntroRoot = styled.div`
    margin: 0 0 120px 0;

    @media (${mediaMd}) {
        margin: 0 0 64px 0;
    }

    @media (${mediaSm}) {
        margin: 0 0 32px 0;
    }
`;

export const Container = styled.div`
    display: flex;
    gap: 9px;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    ${containerMixin}

    @media (${mediaMd}) {
        gap: 8px;

        svg {
            height: 61px;
            width: 61px;
        }
    }

    @media (${mediaSm}) {
        .globe {
            display: none;
        }

        svg {
            height: 33px;
            width: 33px;
        }
    }
`;

export const Text = styled.span<{ outlined?: boolean }>`
    font-size: 91px;
    line-height: 75px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.primary};
    text-align: center;

    @media (${mediaMd}) {
        font-size: 74px;
        line-height: 61px;
    }

    @media (${mediaSm}) {
        font-size: 40px;
        line-height: 32px;
    }

    ${({ theme, outlined }) =>
        outlined &&
        css`
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: ${theme.palette.text.primary};
        `}
`;

export const Date = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
`;

export const DateItems = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
    text-transform: uppercase;
    ${gradientTextMixin}
`;

export const DateItem = styled.span`
    font-size: 32px;
    line-height: 32px;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 18px;
    }
`;

export const ButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    text-align: left;

    @media (${mediaSm}) {
        gap: 12px;
    }

    @media (${mediaXs}) {
        gap: 4px;
    }
`;

export const ButtonIconContainer = styled.div`
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (${mediaMd}) {
        width: 48px;
        height: 48px;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    @media (${mediaSm}) {
        width: 32px;
        height: 32px;
    }
`;

export const ButtonLabel = styled.span`
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;

    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 18px;
    }

    @media (${mediaSm}) {
        font-size: 16px;
        line-height: 16px;
    }
`;

export const typeOutlinedMixin = css`
    background: ${({ theme }) => theme.palette.background};
    height: 69px;

    &:hover {
        background: ${({ theme }) => theme.palette.background};
        opacity: 1;
    }

    &:after {
        background: linear-gradient(216.53deg, #4dff88 15.95%, #3de8ca 83.66%);
    }

    ${ButtonLabel} {
        ${gradientTextMixin({ color: 'linear-gradient(216.53deg, #4DFF88 15.95%, #3DE8CA 83.66%)' })}
    }

    ${ButtonIconContainer} {
        background: linear-gradient(216.53deg, #4dff88 15.95%, #3de8ca 83.66%);
    }

    @media (${mediaMd}) {
        height: 56px !important;
    }

    @media (${mediaSm}) {
        height: 36px !important;
    }
`;

export const typeTransparentMixin = css`
    background: ${({ theme }) => theme.palette.background};
    border: 2px solid rgba(255, 255, 255, 0.8);
    font-weight: 500;
    padding: 7px 7px 8px 24px;

    a {
        span {
            font-weight: 500;
        }
    }

    &:hover {
        background: ${({ theme }) => theme.palette.background};
        opacity: 1;
    }

    &:after {
        display: none;
    }

    ${ButtonLabel} {
        color: rgba(255, 255, 255, 0.8);
    }

    ${ButtonIconContainer} {
        background: rgba(255, 255, 255, 0.8);
    }
`;

export const typeContainedMixin = css`
    background: linear-gradient(90deg, #29c2cc 0%, #4affe9 100%);
    border: none;
    padding: 7px 7px 7px 24px;

    &:hover {
        background: linear-gradient(90deg, #29c2cc 0%, #4affe9 100%);
        opacity: 1;
    }

    &:after {
        display: none;
    }

    ${ButtonLabel} {
        color: ${({ theme }) => theme.palette.black};
    }

    ${ButtonIconContainer} {
        background: ${({ theme }) => theme.palette.white};
    }
`;

const getIntroButtonTypeMixin = (type: IntroButtonType) => {
    switch (type) {
        case 'contained':
            return typeContainedMixin;
        case 'outlined':
            return typeOutlinedMixin;
        case 'transparent':
            return typeTransparentMixin;
    }
};

export const IntroButton = styled(Button)<{ type: IntroButtonType }>`
    position: relative;
    border: 2px solid transparent;
    padding: 5px 5px 5px 24px;
    height: 74px;
    letter-spacing: 0;

    ${({ type }) => getIntroButtonTypeMixin(type)}

    ${ButtonIconContainer} {
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        ${ButtonIconContainer} {
            transform: rotate(45deg);
        }
    }

    &:after {
        position: absolute;
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        content: '';
        z-index: -1;
        border-radius: 48px;

        @media (${mediaXs}) {
            top: -3px;
            bottom: -3px;
            left: -3px;
            right: -3px;
        }
    }

    @media (${mediaMd}) {
        height: 60px;
        padding: 4px 5px 4px 18px;
    }

    @media (${mediaSm}) {
        padding: 2px 3px 2px 12px;
        height: 40px;
        letter-spacing: 0;

        & > span {
            width: 100%;

            ${ButtonContent} {
                justify-content: space-between;
            }
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 18px;
    margin: 18px auto 0;
    ${containerMixin}
`;
