import styled from 'styled-components';
import { PrimaryButton } from 'components/button/styled';
import { mediaMd, mediaXs } from '../../theme/theme-helper-selectors';

export const HiddenInput = styled.input`
    visibility: hidden;
    display: none;
`;

export const DeleteContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
`;

export const LabelContainer = styled.label`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-end;

    @media (${mediaMd}) {
        flex-direction: column;
        align-items: flex-start;

        br {
            display: none;
        }
    }
`;

export const UploadButton = styled(PrimaryButton)`
    border-radius: 8px;
    padding: 14px 24px;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    letter-spacing: 0.5px;
    height: 48px !important;

    @media (${mediaXs}) {
        width: 100%;
    }
`;

export const Label = styled.div`
    font-size: 16px;
    line-height: 18px;

    @media (${mediaXs}) {
        & > span {
            margin-right: 10px;
        }
    }
`;
