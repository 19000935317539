import styled, { css } from 'styled-components';
import { mediaLg, mediaMd, mediaXs } from '../../../../../../../theme/theme-helper-selectors';
import { Tag } from 'components/tags/styled';
import { IconContainer } from 'components/accordion/styled';
import { gradientTextMixin } from 'styles/gradient-text-mixin';

export const EventItemContent = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 32px;
    &:last-child {
        border: none;
    }
    @media (${mediaXs}) {
        flex-direction: column;
    }
`;

export const DescriptionBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 24px;
    @media (${mediaXs}) {
        padding-left: 0px;
        padding-top: 16px;
    }
`;

export const SpeakersBlock = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 58px;
    flex-wrap: wrap;
    padding-top: 24px;
    @media (${mediaLg}) {
        row-gap: 24px;
    }
`;

export const SpeakerBlock = styled.div`
    display: flex;
    flex-direction: row;
    width: 320px;
    cursor: pointer;
`;

export const SpeakerPhoto = styled.div<{ image: string }>`
    width: 78px;
    height: 78px;
    min-width: 78px;
    max-width: 78px;
    min-height: 78px;
    max-height: 78px;
    background-image: url(${({ image }) => image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    @media (${mediaMd}) {
        width: 64px;
        height: 64px;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
    }
`;

export const SpeakerInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0px 16px 16px;
`;

export const SpeakerName = styled.p`
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    color: ${({ theme }) => theme.palette.white};
    text-wrap: wrap;
    @media (${mediaMd}) {
        font-size: 24px;
        line-height: 27px;
    }
    @media (${mediaXs}) {
        font-size: 21px;
        line-height: 24px;
    }
`;

export const SpeakerPosition = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.4);
    @media (${mediaXs}) {
        font-size: 14px;
        line-height: 16px;
    }
    text-wrap: wrap;
`;

export const DescriptionEventBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CloseBlock = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;

export const EventName = styled.p`
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 42px;
    font-weight: 500;
    line-height: 42px;
    ${gradientTextMixin}
    @media (${mediaMd}) {
        font-size: 32px;
        line-height: 36px;
    }
    @media (${mediaXs}) {
        font-size: 24px;
        line-height: 27px;
    }
`;

export const EventDescriptionBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const EventDescription = styled.p`
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
    padding-right: 50px;
    @media (${mediaMd}) {
        font-size: 18px;
        line-height: 21px;
    }
    @media (${mediaXs}) {
        font-size: 16px;
        line-height: 18px;
    }
`;

export const EventDescriptionTagBlock = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    flex-wrap: wrap;
    gap: 10px;
`;

export const EventDescriptionTag = styled(Tag)`
    font-size: 24px;
    font-weight: 500;
    line-height: 27px;
    background: none;
    padding: 0px;
    ${({ active }) =>
        active &&
        css`
            ${gradientTextMixin}
            font-weight: 600;
        `}
    @media (${mediaMd}) {
        font-size: 21px;
        line-height: 24px;
    }
    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 21px;
    }
`;

export const CloseButton = styled(IconContainer)`
    @media (${mediaXs}) {
        margin-top: -50px;
    }
`;
