import styled from 'styled-components';

export const Card = styled.div<{ preventFlip?: boolean }>`
    width: 100%;
    cursor: ${({ preventFlip }) => (preventFlip ? 'pointer' : 'auto')};
    overflow: hidden;
    word-wrap: break-word;
`;

export const Inner = styled.div<{ isActive: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: ${({ isActive }) => (isActive ? 'rotateY(180deg)' : 'none')};
`;

export const Front = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
`;

export const Back = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateY(180deg);
`;
