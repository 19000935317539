import React, { FC } from 'react';
import {
    CardTextP1,
    CardTextP2,
    DescriptionRoot,
    CardBlock,
    BottomMessage,
    SubHeader,
    CardTitle,
    Dragon,
    Date,
    CardBlockWrapper,
} from './styled';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Card } from 'components/card/styled';

const Description: FC = () => {
    const { t } = useTranslation('pages/home');

    return (
        <DescriptionRoot>
            <Card>
                <CardTitle variant="h2" fontWeight="regular">
                    {t('description-title')}
                </CardTitle>
                <CardBlock>
                    <CardTextP1 variant="p1" fontWeight="light">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(t('description')),
                            }}
                        />
                    </CardTextP1>
                </CardBlock>
                <CardBlock>
                    <CardTextP2 variant="p2" fontWeight="light">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(t('description-2')),
                            }}
                        />
                    </CardTextP2>
                </CardBlock>
                <BottomMessage fontWeight={'regular'}>{t('fun')}</BottomMessage>
            </Card>
            <Card>
                <Dragon src="/assets/images/dragon.png" alt="Bali, Indonesia" />

                <CardBlockWrapper>
                    <SubHeader fontWeight={'regular'}>{t('bali')}</SubHeader>
                    <Date>
                        {t('date-month')}, {t('date-year')}
                    </Date>

                    <CardBlock>
                        <CardTextP1 variant="p1" fontWeight="light" lineHeight={18}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(t('description-email')),
                                }}
                            />
                        </CardTextP1>
                    </CardBlock>
                </CardBlockWrapper>
            </Card>
        </DescriptionRoot>
    );
};

export default Description;
