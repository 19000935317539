import React, { FC, useEffect, useRef, useState } from 'react';
import { Block, GradientBackgroundBlock, GradientTitleBlock } from './styled';
import { TypographyTypes } from 'kl-b2c-ui-kit';

interface GradientTitleProps {
    label: string;
    className?: string;
    variant: TypographyTypes;
    startColor: string;
    finishColor: string;
    startLabelColor: string;
    finishLabelColor: string;
    borderRadius?: number;
}

const GradientTitle: FC<GradientTitleProps> = ({
    label,
    className,
    variant,
    startColor,
    finishColor,
    startLabelColor,
    finishLabelColor,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [gradientValue, setGradientValue] = useState<number>(0);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const positionToScreen = rect.top / (window.innerHeight / 100);

                if (positionToScreen <= 100 && positionToScreen >= -10) {
                    setGradientValue(100 - positionToScreen);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Block ref={containerRef}>
            <GradientTitleBlock
                className={className}
                variant={variant}
                width={gradientValue}
                start={startLabelColor}
                finish={finishLabelColor}
            >
                {label}
            </GradientTitleBlock>
            <GradientBackgroundBlock start={startColor} finish={finishColor} width={gradientValue} />
        </Block>
    );
};

export default GradientTitle;
