import React, { FC } from 'react';
import { BlackScreen, IncorrectShare, SuccessEl, IconContainer } from '../styles/coming-soon-styles';
import { Icon } from 'kl-b2c-ui-kit';

interface Props {
    close: () => void;
    title: string;
    text: string;
    gap?: number;
}

const Error: FC<Props> = ({ close, text, gap, title }) => (
    <BlackScreen>
        <SuccessEl gap={gap}>
            <IncorrectShare />
            <IconContainer onClick={close}>
                <Icon name={'CrossThin'} hoverColor="white" size={24} color={'gray'} />
            </IconContainer>

            <div className="root">
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
        </SuccessEl>
    </BlackScreen>
);

export default Error;
