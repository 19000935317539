import styled from 'styled-components';
import { mediaMd, mediaXs } from '../../../../theme/theme-helper-selectors';

export const HomepageRoot = styled.div`
    padding: 0 64px;

    @media (${mediaMd}) {
        padding: 0 48px;
    }

    @media (${mediaXs}) {
        padding: 0 16px;
    }
`;
