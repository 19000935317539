import React, { FC } from 'react';
import { Description, Description2 } from './styled';
import { useTranslation } from 'react-i18next';

const SponsorDescription: FC = () => {
    const { t } = useTranslation('pages/action-page');

    return (
        <>
            <Description>
                <p>{t('sponsor-description')}</p>
            </Description>
            <Description2>
                <p>{t('sponsor-description-1')}</p>
            </Description2>
            <Description2>
                <p>{t('sponsor-description-2')}</p>
                <p>
                    <a href="/files/SAS_2024.pdf" download>
                        {t('sponsor-description-3')}
                    </a>
                </p>
                <p>{t('sponsor-description-4')}</p>
                <p>{t('papers-description-7')}</p>
                <p>
                    <a href={`mailto:${t('sas-mail')}`}>{t('sas-mail')}</a>
                </p>
            </Description2>
        </>
    );
};

export default SponsorDescription;
