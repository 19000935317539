import { Committee, HttpResponse, IApiService, ICommitteeApi } from 'types';

export class CommitteeApi implements ICommitteeApi {
    constructor(private readonly apiService: IApiService) {}

    public getAll(): Promise<HttpResponse<{ count: number; items: Committee[] }>> {
        return this.apiService.request<{ count: number; items: Committee[] }>({
            method: 'GET',
            url: '/committee/get?page=0&size=300',
        });
    }
}
