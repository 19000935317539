import React, { FC } from 'react';
import { BlackScreen, SuccessEl, СorrectShare, IconContainer } from '../styles/coming-soon-styles';
import { Icon } from 'kl-b2c-ui-kit';

interface Props {
    close: () => void;
    gradient?: string;
    color?: string;
    gap?: number;
}

const Success: FC<Props> = ({ close, gradient, color, gap }) => (
    <BlackScreen>
        <SuccessEl gradient={gradient} color={color} gap={gap}>
            {!gradient && <СorrectShare />}
            <IconContainer onClick={close}>
                <Icon name={'CrossThin'} hoverColor="white" size={24} color={'gray'} />
            </IconContainer>

            <div className="root">
                <h4>Thank you for your interest in the SAS 2024!</h4>
                <p>We will get back to you shortly</p>
            </div>
        </SuccessEl>
    </BlackScreen>
);

export default Success;
