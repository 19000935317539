import { IStorageService } from 'types';

export class StorageService implements IStorageService {
    setItem<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getItem<T>(key: string): T {
        const rawData = localStorage.getItem(key);
        return rawData ? JSON.parse(rawData) : null;
    }

    clearItem(key: string) {
        localStorage.removeItem(key);
    }
}
