import React, { FC } from 'react';
import { Description, Description2 } from './styled';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { TOPICS_LIST } from 'consts/faq';

const SpeakerDescription: FC = () => {
    const { t } = useTranslation('pages/action-page');

    return (
        <>
            <Description>
                <p>{t('papers-description')}</p>
                <p>{t('papers-description-2')}</p>
                <p>{t('papers-description-3')}</p>
            </Description>
            <Description2>
                <p>{t('papers-description-4')}</p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(TOPICS_LIST),
                    }}
                />
                <p
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('papers-description-5')),
                    }}
                />
                <p>{t('papers-description-6')}</p>
                <br />
                <p>{t('papers-description-7')}</p>
                <p>
                    <a href={`mailto:${t('sas-mail')}`}>{t('sas-mail')}</a>
                </p>
            </Description2>
        </>
    );
};

export default SpeakerDescription;
