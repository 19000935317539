export const urlEncodeUtil = (params: Record<string, string | number>): string => {
    let result = '';
    Object.entries(params).forEach(([key, value], index) => {
        if (Array.isArray(value)) {
            if (value && value.length > 0) {
                const query = value.map((val) => `&${key}=${val}`).join('');
                result = `${result}&${query}`;
            } else {
                return;
            }
        } else {
            result = `${result}${index === 0 ? '' : '&'}${key}=${value}`;
        }
    });
    return result;
};
