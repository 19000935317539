import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from '../../../../../../theme/theme-helper-selectors';
import { Card } from 'components/card/styled';
import { Button, Typography } from 'kl-b2c-ui-kit';

export const FormCard = styled(Card)`
    background: linear-gradient(180deg, #29c2cc 0%, #4affe9 100%);

    &:before {
        display: none;
    }
`;

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    font-size: 52px;
    line-height: 52px;
    margin: 0 0 32px 0;
    font-weight: 500;

    @media (${mediaMd}) {
        font-size: 42px;
        line-height: 42px;
    }

    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 36px;
        margin: 0 0 24px 0;
    }
`;

export const Description = styled(Typography).attrs({
    variant: 'p2',
})`
    margin: 0 0 32px 0;

    @media (${mediaSm}) {
        margin: 0 0 24px 0;
    }
`;

export const FieldRow = styled.div`
    position: relative;
`;

export const FieldRowDouble = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (${mediaXs}) {
        flex-direction: column;
    }

    ${FieldRow} {
        width: 100%;
    }
`;

export const Error = styled(Typography).attrs({
    variant: 'p5',
})`
    color: ${({ theme }) => theme.palette.energyRed};
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;

    @media (${mediaMd}) {
        flex-direction: column;
    }

    @media (${mediaSm}) {
        flex-direction: row;
    }

    @media (${mediaXs}) {
        flex-direction: column;
    }

    & > div,
    input {
        width: 100%;
    }
`;

export const FormButton = styled(Button)`
    border-radius: 8px;
    padding: 14px 24px;
    font-size: 16px;
    line-height: 20px;
`;
