import React, { FC } from 'react';
import { FooterType } from 'types';
import FooterMenu from './FooterMenu';
import { Copyright, FooterRoot, FooterWrapper, LeftSide, RightSide, FooterText } from './styled';
import SocialLinks from './SocialLinks';

interface Model {
    model: FooterType;
    allowCookieBot?: boolean;
}

const Footer: FC<Model> = ({ model, allowCookieBot }): JSX.Element => (
    <FooterRoot data-at-selector="pageFooter">
        <FooterWrapper>
            <LeftSide>
                <Copyright>{model.copyright}</Copyright>
                <FooterMenu links={model.links} allowCookieBot={allowCookieBot} />
            </LeftSide>
            <RightSide>
                {model.text && <FooterText>{model.text}</FooterText>}
                <SocialLinks icons={model.icons} />
            </RightSide>
        </FooterWrapper>
    </FooterRoot>
);

export default Footer;
