import styled from 'styled-components';
import { mediaSm } from '../../../../../../theme/theme-helper-selectors';
import { IntroButton } from '../../intro/styled';

export const BlockTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 35px 0;

    @media (${mediaSm}) {
        margin: 0 0 48px 0;
    }
`;

export const MoreBtnContainer = styled.div`
    margin: 48px 0 0 0;
    text-align: center;
`;

export const MoreBtn = styled(IntroButton)`
    padding: 4px 18px;
`;
