import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, withTheme } from 'styled-components';
import { BlockTitleContainer, FaqContainer, ContentContainer, Column } from './styled';
import Tags from 'components/tags/Tags';
import { FAQ_ACCORDION, FAQ_TAGS } from 'consts/faq';
import Accordion from 'components/accordion/Accordion';
import UpdatesForm from 'containers/pages/home-page/components/updates-form/UpdatesForm';
import GradientTitle from 'components/gradient-title/GradientTitle';

const Faq: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const { t } = useTranslation('pages/home');
    const [faqItems, setFaqItems] =
        useState<{ key: string; question: string; answer: string; tags: string[] }[]>(FAQ_ACCORDION);

    return (
        <FaqContainer>
            <BlockTitleContainer>
                <GradientTitle
                    variant="h1"
                    label={t('faq-title')}
                    startColor="#29C2CC"
                    finishColor="#4AFFE9"
                    startLabelColor={theme.palette.black}
                    finishLabelColor={theme.palette.white}
                />
            </BlockTitleContainer>
            <Tags
                onChange={(chosen) => {
                    if (!chosen.length) {
                        return setFaqItems(FAQ_ACCORDION);
                    }
                    return setFaqItems(
                        FAQ_ACCORDION.filter((accordion) => accordion.tags.some((tag) => chosen.some((c) => c === tag)))
                    );
                }}
                // @ts-ignore
                tags={FAQ_TAGS.map((tag) => ({ key: tag, label: t(`faq-${tag}`) }))}
            />
            <ContentContainer>
                <Column width={'61%'}>
                    <Accordion questions={faqItems} />
                </Column>
                <Column width={'39%'}>
                    <UpdatesForm />
                </Column>
            </ContentContainer>
        </FaqContainer>
    );
};

export default withTheme(Faq);
