import React, { useRef, useState, useEffect } from 'react';
import { ScreenBg, ScreenBlock, ScreenContent, ScreenTextBlock, Switcher, SwitcherBlock } from './styled';

const Screen = () => {
    const [on, setOn] = useState(true);
    const scaleBlock = useRef<HTMLDivElement | null>(null);
    const screenBlock = useRef<HTMLDivElement | null>(null);

    const screenText = `+------------------------------------------------------+
|                                                      |
|   root@sasctf:~$ cat flag.txt                        |
|   SAS{w3lc0m3_t0_S4S_2024}                           |
|                                                      |
|                   ++++++++++++++++                   |
|               +++++++++++++     ++++++               |
|             +++++++++++  ++   +++   ++++             |
|           +++++++++++++++++++++++     ++++           |
|         ++++     ++++++++++    ++++     ++++         |
|        +++     ++++ +++  ++  ++++++++     +++        |
|       +++     ++ +++  ++++++++      ++     +++       |
|       ++     +++++++++   ++++++++    +++    ++       |
|      ++     ++   ++++  ++++++++ ++  + +++    ++      |
|      ++    ++    +++++ ++++++++++ + +++++++++++      |
|      ++    ++   +   ++++    +++++  +   ++++++++      |
|      +++++++++++++++  ++++++ ++++++++++++++++++      |
|      ++    ++   +     ++    +++ ++ +   +++  +++      |
|      ++    ++    +      ++++      ++++++++  +++      |
|      ++    +++    +             +++++++++++ +++      |
|       ++    +++    ++    ++    +++++++++++++++       |
|       +++     ++     +++++++++++++++++++++++++       |
|        +++     +++       ++++++++++++++++++++        |
|         ++++     ++++    +++++++++++++++++++         |
|           ++++       +++++++++++++++++++++           |
|             ++++         ++ ++++++++++++             |
|               ++++++     ++ +++ ++++++               |
|                   ++++++++++++++++                   |
|                                                      |
+------------------------------------------------------+
    `;

    useEffect(() => {
        const resize = () => {
            if (scaleBlock && screenBlock && scaleBlock.current) {
                const width = screenBlock.current?.offsetWidth ?? 700;
                scaleBlock.current.style.transform = `scale(${width / 1351.4})`;
            }
        };

        resize();

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    return (
        <ScreenBg ref={screenBlock}>
            <img src="/assets/computer/old_computer_v2.png" />
            <ScreenContent ref={scaleBlock}>
                <ScreenBlock>
                    <ScreenTextBlock>{on && <pre>{screenText}</pre>}</ScreenTextBlock>
                    <SwitcherBlock>
                        <Switcher onClick={() => setOn(!on)} />
                    </SwitcherBlock>
                </ScreenBlock>
            </ScreenContent>
        </ScreenBg>
    );
};

export default Screen;
