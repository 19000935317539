import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppSettings, HttpResponse, IApiService, IStorageService } from 'types';
import { LS_KEYS } from 'consts';
import { getSettingsService } from './api.factory';

export class ApiService implements IApiService {
    private axios: AxiosInstance | undefined;

    constructor(private storageService: IStorageService) {}

    public async request<T>(requestConfig: AxiosRequestConfig): Promise<HttpResponse<T>> {
        if (!this.axios) {
            let { apiAddress } = this.storageService.getItem<AppSettings>(LS_KEYS['appSettings']) || {};

            if (!apiAddress) {
                const { apiAddress: fetchedAddress } = await getSettingsService().getSettings();
                apiAddress = fetchedAddress;
            }

            this.axios = axios.create({
                baseURL: apiAddress,
            });
        }

        const response: AxiosResponse = await this.axios.request<T>(requestConfig);
        return {
            data: response.data,
            status: response.status,
        };
    }
}
