import styled, { css } from 'styled-components';
import { Card } from 'components/card/styled';
import { mediaMd, mediaSm, mediaXs } from '../../../theme/theme-helper-selectors';
import { Typography } from 'kl-b2c-ui-kit';
import { gradientTextMixin } from 'styles/gradient-text-mixin';

export const Container = styled.div`
    width: 100%;
    max-width: 1180px;
    margin: 0 auto 120px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;

    @media (${mediaMd}) {
        padding: 0 48px;
    }

    @media (${mediaSm}) {
        margin: 0 auto 64px;
    }

    @media (${mediaXs}) {
        padding: 0 16px;
    }
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;

    // not templated value but we have to do this for design purposes
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const Column = styled(Card)<{ flex?: boolean; width?: number }>`
    width: ${({ width }) => (width ? `${width}px` : `calc(100% - 682px)`)};
    flex-shrink: 0;

    ${({ flex }) =>
        flex &&
        css`
            display: flex;
            flex-direction: column;
            gap: 64px;

            @media (${mediaSm}) {
                flex-direction: row;
                gap: 16px;
            }
            @media (${mediaXs}) {
                flex-direction: column;
            }
        `}

    // not templated value but we have to do this for design purposes
    @media only screen and (max-width: 1200px) {
        width: 100%;
    }
`;

export const CardImage = styled.img`
    width: 332px;

    @media (${mediaSm}) {
        width: 210px;
        height: 210px;
    }

    @media (${mediaXs}) {
        width: 180px;
        height: 180px;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const PageTitle = styled(Typography).attrs({
    variant: 'h1',
})`
    @media (${mediaMd}) {
        font-size: 72px;
        line-height: 72px;
    }
    @media (${mediaSm}) {
        font-size: 48px;
        line-height: 48px;
    }
`;

export const DescriptionTitle = styled(Typography).attrs({
    variant: 'h2',
})`
    ${gradientTextMixin}
    font-size: 54px;
    line-height: 54px;
    font-weight: 500;

    @media (${mediaMd}) {
        font-size: 42px;
        line-height: 42px;
    }

    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 36px;
    }
`;
