import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from '../../../theme/theme-helper-selectors';

export const FooterWrapper = styled.div`
    padding: 24px 64px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (${mediaMd}) {
        padding: 16px 50px 8px;
    }
    @media (${mediaSm}) {
        flex-direction: column;
        padding: 24px 50px 10px;
        align-items: flex-start;
    }
    @media (${mediaXs}) {
        padding: 20px 16px 10px;
    }
`;
