import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import { HttpResponse, IApiService, ISponsorsApi, Sponsor } from 'types';

export class SponsorsApi implements ISponsorsApi {
    constructor(private readonly apiService: IApiService) {}

    public getAll(params: PaginationInput): Promise<HttpResponse<PaginationOutput<Sponsor>>> {
        return this.apiService.request<PaginationOutput<Sponsor>>({
            method: 'GET',
            url: `/sponsorContent/get`,
            params,
        });
    }
}
