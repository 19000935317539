import { TEN_MB_IN_BYTES, ACCEPTED_FILE_FORMATS } from 'consts';
import { sizeConvertor } from './size-convertor';

export const fileValidator = (
    file: File,
    maxSize = TEN_MB_IN_BYTES,
    acceptedFormats = ACCEPTED_FILE_FORMATS,
    t: (key: string, options?: Record<string, number | string>) => string
): boolean | string => {
    if (!file) {
        return true;
    }

    if (file.size > maxSize) {
        return t('validations:fileSize', { size: sizeConvertor(TEN_MB_IN_BYTES) });
    }

    if (!acceptedFormats.includes(file.name.split('.')[1])) {
        return t('validations:fileFormat', { formats: acceptedFormats });
    }

    return true;
};
