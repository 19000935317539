import React, { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import { Card } from 'components/card/styled';
import { Wrapper, Question, AnswerContainer, AnswerContent, IconContainer, QuestionText, Disclaimer } from './styled';
import { Icon } from 'kl-b2c-ui-kit';
import { useTranslation } from 'react-i18next';

type AccordionProps = {
    questions: { key: string; question: string; answer: string }[];
};

const Accordion: FC<AccordionProps> = ({ questions }) => {
    const { t } = useTranslation('pages/home');
    const [activeKeys, setActiveKeys] = useState<string[]>([questions[0].key]);
    const handleItemClick = (key: string) => {
        setActiveKeys((prev) => {
            if (prev.some((p) => p === key)) {
                return prev.filter((p) => p !== key);
            } else {
                return [...prev, key];
            }
        });
    };

    return (
        <Card>
            {questions.map((question) => (
                <Wrapper key={question.key}>
                    <Question onClick={() => handleItemClick(question.key)}>
                        <QuestionText>{question.question}</QuestionText>
                        <IconContainer>
                            <Icon
                                size={20}
                                color={'teal'}
                                name={activeKeys.some((key) => key === question.key) ? 'Minus' : 'Plus'}
                            />
                        </IconContainer>
                    </Question>

                    <AnswerContainer active={activeKeys.some((key) => key === question.key)}>
                        <AnswerContent
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(question.answer, { ADD_ATTR: ['target'] }),
                            }}
                        />
                    </AnswerContainer>
                </Wrapper>
            ))}

            <Disclaimer
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t('faq-disclaimer')),
                }}
            />
        </Card>
    );
};

export default Accordion;
