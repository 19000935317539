import styled, { css } from 'styled-components';
import { Typography } from 'kl-b2c-ui-kit';

export const Form = styled.form<{ gap: string; loading?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap};
    height: 100%;
    ${({ loading }) =>
        loading &&
        css`
            pointer-events: none;
            opacity: 0.6;
        `}
`;

export const Disclaimer = styled(Typography).attrs({
    variant: 'p6',
})<{ type: 'primary' | 'secondary' }>`
    color: ${({ type }) => (type === 'primary' ? 'rgba(255, 255, 255, 0.6)' : 'rgb(25, 25, 24)')};
    opacity: 1;
    a {
        color: ${({ theme, type }) => (type === 'primary' ? '#3DE8CA' : theme.palette.text.secondary)};
        font-weight: 600;
    }
`;
