import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgramSession } from 'types/programs';
import {
    CardBlock,
    DescriptionEventBlock,
    EmptyEventsBlock,
    EndTime,
    EventContent,
    EventEmptyDescriptionBlock,
    EventsExistBlock,
    HideText,
    LocationBlock,
    LocationDescription,
    ModeratorEventBlock,
    ModeratorName,
    SessionDescription,
    SessionName,
    SessionTimeBlock,
    StartTime,
} from './styled';
import DOMPurify from 'dompurify';
import { EventItem } from '../event-item';

interface IProps {
    session: ProgramSession;
    selectedTagsId: string[];
    select: (id: string, val: boolean) => void;
}

export const Session: FC<IProps> = ({ session, selectedTagsId, select }) => {
    const { t } = useTranslation('pages/home');

    return (
        <CardBlock>
            {session.programEvents.length ? (
                <>
                    <EventsExistBlock>
                        <DescriptionEventBlock>
                            <HideText>{session.name}</HideText>
                            <SessionDescription
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(session.description) }}
                            />
                        </DescriptionEventBlock>
                        {session.moderator && (
                            <ModeratorEventBlock>
                                <HideText>{t('moderator')}</HideText>
                                <ModeratorName>
                                    {session.moderator?.firstName} {session.moderator?.lastName}
                                </ModeratorName>
                                {session?.moderator?.position && (
                                    <HideText>{session?.moderator?.position ?? ''}</HideText>
                                )}
                            </ModeratorEventBlock>
                        )}
                    </EventsExistBlock>
                    <EventContent>
                        {session.programEvents
                            .filter((r) => !selectedTagsId.length || r.tags.some((t) => selectedTagsId.includes(t.id)))
                            .map((e) => (
                                <EventItem key={e.id} eventItem={e} selectedTagsId={selectedTagsId} select={select} />
                            ))}
                    </EventContent>
                </>
            ) : (
                <EmptyEventsBlock>
                    <SessionTimeBlock>
                        <StartTime>{session.startTime}</StartTime>
                        <EndTime>-{session.endTime}</EndTime>
                    </SessionTimeBlock>
                    <EventEmptyDescriptionBlock>
                        <SessionName>{session.name}</SessionName>
                    </EventEmptyDescriptionBlock>
                    <LocationBlock>
                        <LocationDescription>{session?.location ?? ''}</LocationDescription>
                    </LocationBlock>
                </EmptyEventsBlock>
            )}
        </CardBlock>
    );
};
