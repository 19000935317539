import React, { FC } from 'react';
import { BlackScreen, InfinityProgress, LoadingProcess } from '../styles/coming-soon-styles';

interface Props {
    label?: string;
}

const Loading: FC<Props> = ({ label = 'Loading location...' }) => (
    <BlackScreen>
        <LoadingProcess>
            <p>
                <span>{label}</span>
            </p>
            <InfinityProgress>
                <div className="color" />
            </InfinityProgress>
        </LoadingProcess>
    </BlackScreen>
);

export default Loading;
