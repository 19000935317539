import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { LogoWrapper, HeaderRoot, HeaderWrapper } from './styled';
import { HeaderMenu } from 'types';
import { ReactComponent as Logo } from '../../assets/icons/sas-logo.svg';
import { NavList, NavListItem } from 'components/header/header-nav/styled';
import HeaderLink from 'components/header/header-nav/header-link/HeaderLink';
import { useMobileMenuContext } from 'contexts/mobile-menu.context';

export interface HeaderProps {
    links: HeaderMenu[];
}

const BottomHeader: FC<HeaderProps> = (props) => {
    const { isMobileMenuOpened } = useMobileMenuContext();

    return (
        <HeaderRoot bottom menuOpened={isMobileMenuOpened}>
            <HeaderWrapper>
                <LogoWrapper>
                    <Link to="/">
                        <Logo />
                    </Link>
                </LogoWrapper>
                <NavList>
                    {props.links.map((item) => (
                        <NavListItem key={item.title}>
                            <HeaderLink {...item} />
                        </NavListItem>
                    ))}
                </NavList>
            </HeaderWrapper>
        </HeaderRoot>
    );
};

export default BottomHeader;
