import styled, { css } from 'styled-components';
import { HamburgerLine } from './line';
import { headerHeight, mediaSm } from '../../../../theme/theme-helper-selectors';

export const HamburgerButton = styled.button<{
    isMobileMenuOpened: boolean;
}>`
    display: none;

    @media (${mediaSm}) {
        transition: all 0.3s ease-in-out;
        width: 64px;
        height: ${headerHeight};
        flex-shrink: 0;
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        padding: 0;
        outline: none !important;
        border: 0;
        background: transparent;
        z-index: 100;

        &:hover {
            cursor: pointer;
            ${() => css`
                ${HamburgerLine} {
                    background: ${({ theme }) => theme.palette.green};
                }
            `}
        }

        ${({ isMobileMenuOpened }) =>
            isMobileMenuOpened &&
            css`
                ${HamburgerLine} {
                    width: 22px;
                }

                ${HamburgerLine}:nth-child(1) {
                    transform: translateY(0) rotate(45deg);
                }

                ${HamburgerLine}:nth-child(2) {
                    transform: translateY(-7px) rotate(-45deg);
                }
            `}
    }
`;
