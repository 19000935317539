import { HomePageSetting, HttpResponse, IApiService, IProgramApi } from 'types';

export class ProgramApi implements IProgramApi {
    constructor(private readonly apiService: IApiService) {}

    public getSettings(): Promise<HttpResponse<HomePageSetting>> {
        return this.apiService.request<HomePageSetting>({
            method: 'GET',
            url: `/Program/GetSettings`,
        });
    }
}
