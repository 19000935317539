import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import FormBase from 'components/form-base/FormBase';
import { Error, FieldRow, FieldRowDouble } from 'containers/pages/home-page/components/updates-form/styled';
import { FieldsContainer, Divider } from './styled';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { TextField } from 'kl-b2c-ui-kit';
import { Participant } from 'types';
import { DefaultTheme, withTheme } from 'styled-components';
import { generateRules } from 'containers/pages/action-page/components/rules';
import FileUploader from 'components/file-uploader/FileUploader';
import { ActionPageKey } from 'enums/action-page-key';

type RegistrationFormProps = {
    theme: DefaultTheme;
    submit: (data: FieldValues) => void;
    loading: boolean;
    hasPresentation?: boolean;
    hidePosition?: boolean;
    actionPageKey: ActionPageKey;
};

const RegistrationForm: FC<RegistrationFormProps> = ({
    theme,
    submit,
    hasPresentation,
    loading,
    hidePosition,
    actionPageKey,
}) => {
    const { t } = useTranslation(['pages/action-page', 'validations']);
    const {
        control,
        formState: { errors },
    } = useFormContext<Participant>();

    const getPPmessage = (): string => {
        switch (actionPageKey) {
            case ActionPageKey.Papers:
                return t('pages/action-page:pp-papers');
            case ActionPageKey.Sponsor:
                return t('pages/action-page:pp-sponsor');

            default:
                return t('pages/action-page:pp');
        }
    };

    return (
        <FormBase
            gap={'24px'}
            policies={[
                {
                    name: 'agreement.privacyPolicyIsSelected',
                    label: getPPmessage(),
                    required: true,
                },
                {
                    name: 'agreement.marketingIsSelected',
                    label: t('pages/action-page:marketing'),
                },
            ]}
            submit={submit}
            loading={loading}
            submitLabel={t('pages/action-page:submit')}
        >
            <FieldsContainer>
                <FieldRowDouble>
                    <Controller
                        name={'firstName'}
                        control={control}
                        rules={generateRules('firstName', t)}
                        render={({ field: { onChange, value } }) => (
                            <FieldRow>
                                <TextField
                                    value={value}
                                    type={'text'}
                                    variant={'border'}
                                    transparent
                                    color={theme.palette.text.primary}
                                    placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                    placeholder={t('pages/action-page:first-name')}
                                    onChange={(value) => onChange(value)}
                                    error={!!errors['firstName']}
                                />
                                {errors?.firstName && (
                                    <Error variant="p5">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    errors?.firstName?.message ??
                                                    DOMPurify.sanitize(t('validations:default')),
                                            }}
                                        />
                                    </Error>
                                )}
                            </FieldRow>
                        )}
                    />
                    <Controller
                        name={'lastName'}
                        control={control}
                        rules={generateRules('lastName', t)}
                        render={({ field: { onChange, value } }) => (
                            <FieldRow>
                                <TextField
                                    value={value}
                                    type={'text'}
                                    variant={'border'}
                                    transparent
                                    color={theme.palette.text.primary}
                                    placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                    placeholder={t('pages/action-page:last-name')}
                                    onChange={(value) => onChange(value)}
                                    error={!!errors['lastName']}
                                />
                                {errors?.lastName && (
                                    <Error variant="p5">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    errors?.lastName?.message ??
                                                    DOMPurify.sanitize(t('validations:default')),
                                            }}
                                        />
                                    </Error>
                                )}
                            </FieldRow>
                        )}
                    />
                </FieldRowDouble>
                <Controller
                    name={'email'}
                    control={control}
                    rules={generateRules('email', t)}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                color={theme.palette.text.primary}
                                placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                placeholder={t('pages/action-page:email-placeholder')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['email']}
                            />
                            {errors?.email && (
                                <Error variant="p5">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.email?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
                <Controller
                    name={'company'}
                    control={control}
                    rules={generateRules('company', t)}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                color={theme.palette.text.primary}
                                placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                placeholder={t('pages/action-page:company')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['company']}
                            />
                            {errors?.company && (
                                <Error variant="p5">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.company?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
                {!hidePosition && (
                    <Controller
                        name={'position'}
                        control={control}
                        rules={!hidePosition ? generateRules('position', t) : { required: false }}
                        render={({ field: { onChange, value } }) => (
                            <FieldRow>
                                <TextField
                                    value={value}
                                    type={'text'}
                                    variant={'border'}
                                    transparent
                                    color={theme.palette.text.primary}
                                    placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                    placeholder={t('pages/action-page:position')}
                                    onChange={(value) => onChange(value)}
                                    error={!!errors['position']}
                                />
                                {errors?.position && (
                                    <Error variant="p5">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    errors?.position?.message ??
                                                    DOMPurify.sanitize(t('validations:default')),
                                            }}
                                        />
                                    </Error>
                                )}
                            </FieldRow>
                        )}
                    />
                )}
                <Controller
                    name={'phone'}
                    control={control}
                    rules={generateRules('phone', t)}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                color={theme.palette.text.primary}
                                placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                placeholder={t('pages/action-page:phone')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['phone']}
                            />
                            {errors?.phone && (
                                <Error variant="p5">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.phone?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
                {hasPresentation && (
                    <>
                        <Divider />
                        <Controller
                            name={'topic'}
                            control={control}
                            rules={generateRules('topic', t)}
                            render={({ field: { onChange, value } }) => (
                                <FieldRow>
                                    <TextField
                                        value={value}
                                        type={'text'}
                                        variant={'border'}
                                        transparent
                                        color={theme.palette.text.primary}
                                        placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                        placeholder={t('pages/action-page:topic')}
                                        onChange={(value) => onChange(value)}
                                        error={!!errors['topic']}
                                    />
                                    {errors?.topic && (
                                        <Error variant="p5">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        errors?.topic?.message ??
                                                        DOMPurify.sanitize(t('validations:default')),
                                                }}
                                            />
                                        </Error>
                                    )}
                                </FieldRow>
                            )}
                        />
                    </>
                )}
                <Controller
                    name={'comments'}
                    control={control}
                    rules={hasPresentation ? generateRules('description', t) : generateRules('comments', t)}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                color={theme.palette.text.primary}
                                placeholderColor={'rgba(255, 255, 255, 0.6)'}
                                placeholder={
                                    hasPresentation
                                        ? t('pages/action-page:description')
                                        : t('pages/action-page:comments')
                                }
                                onChange={(value) => onChange(value)}
                                error={!!errors['comments']}
                                isTextArea
                            />
                            {errors?.comments && (
                                <Error variant="p5">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.comments?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />

                {hasPresentation && (
                    <>
                        <FieldRow>
                            <Controller
                                name={'file'}
                                control={control}
                                rules={generateRules('file', t)}
                                defaultValue={null}
                                render={({ field: { onChange, value } }) => (
                                    <FileUploader
                                        change={(file) => onChange(file)}
                                        placeholder={'.docx, .pdf, .pptx'}
                                        value={value}
                                    />
                                )}
                            />
                            {errors?.file && (
                                <Error variant="p5">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.file?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                        <Divider />
                    </>
                )}
            </FieldsContainer>
        </FormBase>
    );
};

export default withTheme(RegistrationForm);
