import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiService, getProgramApi, getStorageService } from 'services/api.factory';
import { DefaultTheme, withTheme } from 'styled-components';
import { DateSession, ProgramSession, ProgramTag, SelectedDates } from 'types/programs';
import { BlockTitleContainer, ProgramsContainer } from './styled';
import GradientTitle from 'components/gradient-title/GradientTitle';
import Tags from 'components/tags/Tags';
import {
    CurrentDate,
    DatesContent,
    DatesMonth,
    DatesNumber,
    DatesTag,
    DatesTagContent,
    DayOfWeek,
    MonthNumber,
    SessionsBlock,
    TagContainer,
} from './styled/programs';
import dayjs from 'dayjs';
import { Session } from './components';

const Programs: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const { t } = useTranslation('pages/home');

    const [init, setInit] = useState(true);

    const [tags, setTags] = useState<ProgramTag[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const [currentSessions, setCurrentSessions] = useState<ProgramSession[]>();
    const [sessions, setSessions] = useState<DateSession[]>([]);
    const [selectedDate, setSelectedDate] = useState<SelectedDates[]>([]);

    const storageService = getStorageService();
    const apiService = getApiService(storageService);
    const programsApiService = getProgramApi(apiService);

    useEffect(() => {
        programsApiService
            .getSettings()
            .then((data) => {
                setTags(data?.data?.tags ?? []);
                setSelectedDate(
                    data?.data?.sessions?.map((d, i) => {
                        if (i === 0) setCurrentSessions(d.sessions);
                        return {
                            date: d.date,
                            isSelected: i === 0,
                        };
                    }) ?? []
                );

                setSessions(data?.data?.sessions ?? []);
            })
            .finally(() => {
                setInit(false);

                setTimeout(() => {
                    const { hash } = window.location;

                    const elementToScroll = document.getElementById(hash?.replace('#', ''));

                    if (!elementToScroll || hash !== '#programs') return;

                    window.scrollTo({
                        top: elementToScroll.offsetTop,
                    });
                }, 500);
            });
    }, []);

    useEffect(() => {
        if (init) return;

        const newSessions = [
            ...sessions.filter(
                (innerSession) =>
                    !selectedTags.length ||
                    innerSession.sessions.some((is) =>
                        is.programEvents.some((ie) => ie.tags.some((it) => selectedTags.includes(it.id)))
                    )
            ),
        ];
        const oldSelectedDate = selectedDate.find((r) => r.isSelected);
        const newDates: SelectedDates[] = [
            ...newSessions.map((m) => {
                const isCurrent = oldSelectedDate?.date === m.date;
                return {
                    date: m.date,
                    isSelected: isCurrent,
                };
            }),
        ];
        if (newDates.length && newDates.findIndex((r) => r.isSelected) <= 0) {
            newDates[0].isSelected = true;
        }
        const selectedIndex = newDates.findIndex((r) => r.isSelected);
        setCurrentSessions(selectedIndex >= 0 ? newSessions[selectedIndex].sessions : []);
        setSelectedDate(newDates);
    }, [selectedTags]);

    const getDate = (): dayjs.Dayjs | undefined => {
        const date = selectedDate.find((r) => r.isSelected);

        if (!date) return;

        return dayjs(date.date);
    };
    return sessions.length ? (
        <ProgramsContainer id="programs">
            <BlockTitleContainer>
                <GradientTitle
                    variant="h1"
                    label={t('agenda-title')}
                    startColor="#29C2CC"
                    finishColor="#4AFFE9"
                    startLabelColor={theme.palette.black}
                    finishLabelColor={theme.palette.white}
                />
            </BlockTitleContainer>
            <Tags
                selectedTags={selectedTags}
                onChange={(chosen) => {
                    setSelectedTags(chosen);
                }}
                tags={tags.map(({ id, name }) => ({ key: id, label: name }))}
            />
            <DatesContent>
                {currentSessions?.length ? (
                    <>
                        <CurrentDate>
                            <DayOfWeek>{getDate()?.format('dddd,')}</DayOfWeek>
                            <MonthNumber>{getDate()?.format('MMMM DD')}</MonthNumber>
                        </CurrentDate>
                        <DatesTagContent>
                            {selectedDate.map((m, idx) => (
                                <DatesTag
                                    key={`dates-tag-${m.date}`}
                                    active={m.isSelected}
                                    onClick={() => {
                                        const newVal = selectedDate.map((g, i) => ({
                                            ...g,
                                            isSelected: i === idx,
                                        }));
                                        setSelectedDate(newVal);
                                        setCurrentSessions(sessions[idx].sessions);
                                    }}
                                >
                                    <TagContainer>
                                        <DatesNumber>{dayjs(m.date).format('DD')}</DatesNumber>
                                        <DatesMonth>{dayjs(m.date).format('MMMM')}</DatesMonth>
                                    </TagContainer>
                                </DatesTag>
                            ))}
                        </DatesTagContent>
                    </>
                ) : (
                    <CurrentDate>{t('nothing-was-found')}</CurrentDate>
                )}
            </DatesContent>
            <SessionsBlock>
                {currentSessions &&
                    currentSessions
                        .filter(
                            (f) =>
                                !selectedTags.length ||
                                f.programEvents.some((pe) => pe.tags.some((pt) => selectedTags.includes(pt.id)))
                        )
                        .map((s) => (
                            <Session
                                key={s.id}
                                session={s}
                                selectedTagsId={selectedTags}
                                select={(id, val) => {
                                    if (val) {
                                        setSelectedTags([...selectedTags, id]);
                                        return;
                                    }

                                    setSelectedTags([...selectedTags.filter((r) => r !== id)]);
                                }}
                            />
                        ))}
            </SessionsBlock>
        </ProgramsContainer>
    ) : null;
};

export default withTheme(Programs);
