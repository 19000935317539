import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HeaderNav } from './header-nav/HeaderNav';
import { HeaderHamburger } from './header-hamburger/HeaderHamburger';
import { LogoWrapper, HeaderRoot, HeaderWrapper } from './styled';
import { HeaderMenu } from 'types';
import { ReactComponent as Logo } from '../../assets/icons/sas-logo.svg';

export interface HeaderProps {
    links: HeaderMenu[];
}

const Header: FC<HeaderProps> = (props) => {
    const location = useLocation();

    useEffect(() => {
        setIsSticky(false);
        window.scroll(0, 0);
    }, [location]);

    const headerRef = useRef<HTMLHeadElement>(null);
    const [isSticky, setIsSticky] = useState<boolean>(false);

    const handleScroll = () => {
        if (headerRef.current) {
            const headerHeight = headerRef.current.getBoundingClientRect().height;
            if (headerHeight < window.scrollY) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }
    };

    useEffect(() => {
        const handleScrollEvent = () => {
            handleScroll();
        };

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    return (
        <HeaderRoot ref={headerRef} isSticky={isSticky}>
            <HeaderWrapper isSticky={isSticky}>
                <LogoWrapper>
                    <Link to="/">
                        <Logo />
                    </Link>
                </LogoWrapper>
                <HeaderNav {...props} />
                <HeaderHamburger />
            </HeaderWrapper>
        </HeaderRoot>
    );
};

export default Header;
