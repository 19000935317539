import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { ACCEPTED_FILE_FORMATS } from 'consts';
import { useTranslation } from 'react-i18next';
import { Icon } from 'kl-b2c-ui-kit';
import { DeleteContainer, LabelContainer, HiddenInput, UploadButton, Label } from './styled';

interface FileUploaderProps {
    change: (file: File | null) => void;
    accept?: string;
    placeholder?: string;
    value?: File | null;
}

const FileUploader: FC<FileUploaderProps> = (props) => {
    const { change, accept = ACCEPTED_FILE_FORMATS, placeholder, value } = props;
    const { t } = useTranslation('pages/action-page');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [file, setFile] = useState<File | null | undefined>(null);

    useEffect(() => {
        setFile(value);
    }, [value]);

    return file ? (
        <DeleteContainer>
            <span>{file.name}</span>
            <Icon
                onClick={() => {
                    setFile(null);
                    change(null);
                }}
                name={'Trash'}
                size={24}
                color="teal"
            />
        </DeleteContainer>
    ) : (
        <LabelContainer>
            <HiddenInput
                ref={inputRef}
                id={'file-uploader'}
                type={'file'}
                accept={accept}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const files = (event.target as HTMLInputElement).files;
                    if (files && files[0]) {
                        setFile(files[0]);
                        change(files[0]);
                    }
                }}
            />
            <UploadButton onClick={() => inputRef?.current?.click()}>{t('materials')}</UploadButton>
            <Label>
                <span>{placeholder ? placeholder : accept}</span>
                <br />
                <span>{t('file-uploader-label')}</span>
            </Label>
        </LabelContainer>
    );
};

export default FileUploader;
