import { FooterSocialLinkType, FooterType } from 'types';

const socialLinks: FooterSocialLinkType[] = [
    {
        link: 'https://www.facebook.com/securelist',
        iconName: 'Facebook',
        type: 'facebook',
    },
    {
        link: 'https://www.instagram.com/thesascon/',
        iconName: 'Instagram',
        type: 'instagram',
    },
    {
        link: 'https://twitter.com/TheSAScon',
        iconName: 'Twitter',
        type: 'twitter',
    },
    {
        link: 'https://www.youtube.com/channel/UCGhEv7BFBWdo0k4UXTm2eZg',
        iconName: 'Youtube',
        type: 'youtube',
    },
];

export const getSocialLinks = () => socialLinks.filter((item) => !item.disabled);

export const footerModel: FooterType = {
    links: [
        {
            title: 'Cookies',
            isExternal: false,
            dataAtSelector: 'footerTou',
            onClick: () => window.Cookiebot.renew(),
            link: '',
        },
        {
            title: 'Contact us',
            isExternal: false,
            link: 'mailto:thesascon@thesascon.com',
            dataAtSelector: 'footerContacts',
        },
        {
            title: 'Privacy policy',
            isExternal: true,
            link: 'https://www.kaspersky.com/web-privacy-policy',
            dataAtSelector: 'footerPrivacy',
        },
    ],
    copyright: `© ${new Date().getFullYear()} AO Kaspersky Lab`,
    icons: getSocialLinks(),
};
