import React, { FC, useState } from 'react';
import { ProgramEvent } from 'types/programs';
import DOMPurify from 'dompurify';
import {
    CloseBlock,
    CloseButton,
    DescriptionBlock,
    DescriptionEventBlock,
    EventDescription,
    EventDescriptionBlock,
    EventDescriptionTag,
    EventDescriptionTagBlock,
    EventItemContent,
    EventName,
    SpeakerBlock,
    SpeakerInfo,
    SpeakerName,
    SpeakerPhoto,
    SpeakerPosition,
    SpeakersBlock,
} from './styled';
import { EndTime, SessionTimeBlock, StartTime } from '../session/styled';
import { Icon } from 'kl-b2c-ui-kit';

interface IProps {
    eventItem: ProgramEvent;
    selectedTagsId: string[];
    select: (id: string, val: boolean) => void;
}

export const EventItem: FC<IProps> = ({ eventItem, select, selectedTagsId }) => {
    const [opened, setOpened] = useState(false);

    return (
        <EventItemContent>
            <SessionTimeBlock>
                <StartTime>{eventItem.startTime}</StartTime>
                <EndTime>-{eventItem.endTime}</EndTime>
            </SessionTimeBlock>
            <DescriptionBlock>
                <DescriptionEventBlock>
                    <CloseBlock onClick={() => setOpened(!opened)}>
                        <EventName
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(eventItem.name ?? ''),
                            }}
                        />
                        <CloseButton>
                            <Icon size={20} color={'teal'} name={opened ? 'Minus' : 'Plus'} />
                        </CloseButton>
                    </CloseBlock>
                    {opened && (
                        <EventDescriptionBlock>
                            <EventDescription
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(eventItem.description ?? ''),
                                }}
                            />
                            {eventItem.tags.length && (
                                <EventDescriptionTagBlock>
                                    {eventItem.tags.map((t) => (
                                        <EventDescriptionTag
                                            key={t.id}
                                            active={selectedTagsId.includes(t.id)}
                                            onClick={() => {
                                                select(t.id, !selectedTagsId.includes(t.id));
                                            }}
                                        >
                                            #{t.name}
                                        </EventDescriptionTag>
                                    ))}
                                </EventDescriptionTagBlock>
                            )}
                        </EventDescriptionBlock>
                    )}
                </DescriptionEventBlock>
                {eventItem.speakers.length && (
                    <SpeakersBlock>
                        {eventItem.speakers.map((s) => (
                            <SpeakerBlock
                                key={s.id}
                                onClick={() => {
                                    const link = document.querySelector(`#p${s.id}`);
                                    if (link) link.scrollIntoView();
                                }}
                            >
                                <SpeakerPhoto image={s.image?.fileLink ?? ''} />
                                <SpeakerInfo>
                                    <SpeakerName>
                                        {s.firstName ?? ''} {s.lastName ?? ''}
                                    </SpeakerName>
                                    <SpeakerPosition>{s.position ?? ''}</SpeakerPosition>
                                </SpeakerInfo>
                            </SpeakerBlock>
                        ))}
                    </SpeakersBlock>
                )}
            </DescriptionBlock>
        </EventItemContent>
    );
};
